@charset "UTF-8";
/* Scss variable  Created By Bikash on 21-Jan-2019 */
/* GRADIENT LISTS
============================================= */
@font-face {
  font-family: 'Font-Light';
  src: url('../fonts/DIN2014-Regular.eot');
  src: local('../fonts/DIN 2014 Regular'), local('DIN2014-Regular'),
      url('../fonts/DIN2014-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN2014-Regular.woff') format('woff'),
      url('../fonts/DIN2014-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Font-bold';
  src: url('../fonts/DIN2014-ExtraBold.eot');
  src: local('../fonts/DIN 2014 ExtraBold'), local('DIN2014-ExtraBold'),
      url('../fonts/DIN2014-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN2014-ExtraBold.woff') format('woff'),
      url('../fonts/DIN2014-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
}

html {
  
 
}

body {
  font-size: 14px;
  margin: 0;
  padding: 0;
   /* background: #F1F1F1; */
  /* color: #414D55; */
   /* font-family: 'Barlow', sans-serif;  */
  font-family: "Inter";
  line-height: 1 !important;
}

a
 {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #1e1e2d;
  text-decoration: none;
  cursor: pointer;
}
a:hover,a:focus,
a:focus:hover {
  color: #646479;
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  font-family: Inter;
  font-weight: 600;
}

h1 {
  font-size: 2.9rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem ;
}

h5 {
  font-size: 1.6rem ;
}

h6 {
  font-size: 1.3rem ;
}

p {
  line-height: 1.2em;
}
.text-base{
  /* color: #28856D!important; */
  /* color: #7685FC!important; */
  color: #000 !important;
  
}
@keyframes rippleDrop {
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
}

.dropdown-toggle:after {
  border: 0px;
  margin: 0px;
}

.colon {
  display: none;
}
@media screen and (min-width: 992px) {
  .colon {
    position: absolute;
    left: 5px;
    display: block;
    top: 4px;
  }
}

.overlay {
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    visibility: hidden;
    z-index: 1031;
    position: fixed;
    top: 0;
    bottom: 0;
}


.form-group {
  margin-bottom: 1rem;
}
.form-group label {
  margin-bottom: 0.4rem;
  margin-top: 0.3rem;
  color: #717171;
}
@media screen and (min-width: 1440px) {
  .form-group {
    margin-bottom: 1.5rem;
  }
}

.common-table-search {
  width: 250px;
  display: none;
}
@media screen and (min-width: 768px) {
  .common-table-search {
    display: block;
  }
}

.form-control,.form-select {
  border-radius: 4px!important;
  border: 1px solid #747576;
  color: #000;
  font-family: "inter";
  font-weight: 500;
  /* font-size: 1rem; */
  font-size: 14px;
  padding: .25rem .75rem;
}
.form-control1,.form-select1 {

  border-radius: 5px !important;
  /* color: #fff; */
  font-family: "inter";
  /* font-size: 1rem; */
  font-size: 14px;
  font-weight: 500;
  padding: .25rem .75rem;
}
.vinay{
  background: linear-gradient(180deg, #7685FC 40.46%, #D814F3 168.6%);

}
.form-control{
  border: 1px solid #747576;
   /* background: #f5f5f5; */
}
.form-control.bordered {
  padding: 0.375rem 0.75rem;
  border: 1px solid #dadada;
  border-radius: 3px;
  box-shadow: none;
}
.form-control.danger {
  border: 1px solid #E8E8E8;
  border-color: #f44336;
}
.form-control.success {
  border: 1px solid #E8E8E8;
  border-color: #92c755;
}

.form-control:focus, .form-control:hover {
  /* border: 1px solid #E8E8E8; */
  border-color: #b3b3b3;
  box-shadow: none;
  outline:none;  
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f0f5f8;
}

.control-group {
  position: relative;
}
.control-group label {
  position: absolute;
  left: 0;
  top: 7px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 0;
  padding: 0 .4rem;
}
.control-group label{
  left: .6rem;
  font-size: 1.1rem;
}
.control-group label.input-group-label {
  left: 45px;
}

.control-group .form-group {
  margin-bottom: 1.5rem;
}
.control-group.focused label.input-group-label {
  top: -1.1rem;
  font-size: 1rem;

}
.control-group.focused label {
 top: -.7rem;
 font-size: 1rem;
 left: 7px;
 background: #fff;
 font-weight: 600;
 z-index: 2;
}
.control-group.focused label.input-group-label {
  left: 0;
}
.control-group.focused .form-control {
  border-color:#818181;
}
.control-group .input-group .form-control,.control-group .input-group .form-select {
  border: 0;
  border: 1px solid #747576;
  box-shadow: none;
  background-color: transparent;
  border-radius: 4px 0 0 4px!important;
  border-right: 0px;
}
.control-group .form-control:focus,.control-group .form-select:focus {
  border-color: #000;
  text-shadow: none;
  z-index: 0;
}
.control-group .input-group-text {
  border: 0;
  background: transparent;
  border: 1px solid #ced4da;
  box-shadow: none;
  border-left: 0px;
  border-radius: 0 4px 4px 0;
}
.control-group .form-control,.control-group .form-select{
  padding: .35rem .75rem;
  background-color: transparent;
  /* font-size: 1.2rem; */
  border: 1px solid #747576;
  font-size: 14px;
  position: relative;
  z-index: 1;
  height: 44px;
}


.control-group .form-control:focus + .control-label, :not(.control-group .form-control[value=""]) + .control-label{
    background-color: white;
    font-size: 14px;
    color: black;
    transform: translate(0, -170%);
}
.control-group .form-control:focus + .control-label{
    border-color: blueviolet;
    color: blueviolet;
}

.control-label {
  margin-bottom: 0.1rem;
}
@media screen and (min-width: 992px) {
  .control-label {
    text-align: right;
  }
}

.input-group-text {
  border-radius: 2px;
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
}

.datepicker {
  border-radius: 2px;
}

.ripple {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleEffect {
  animation: rippleDrop 0.5s linear;
}

.btn {
  cursor: pointer;
  padding: 0.2rem 1rem;
  border-radius: 2px;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 1px 4px #d7dfe2;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
@media screen and (min-width: 768px) {
  .btn {
    padding: 0.35rem 1rem;
  }
}
.btn:focus {
  box-shadow: none;
}
.btn.btn-default, .btn.btn-default:focus {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.07) !important;
  color: #7a878e;
}
.btn.btn-primary, .btn.btn-primary:focus {
  /* background-color: #1B4B82;
  border-color: #1B4B82 !important; */
  background-color: #0089FF;
  border-color: #0089FF !important;
  color: #fff;
  border-radius: "5px";
  width: 121px;
}
.btn.btn-primary:hover, .btn.btn-primary:focus:hover {
  /* background-color: #1B4B82;
  border-color: #1B4B82; */
  background-color: #0089FF;
  border-color: #0089FF;
  width: 121px;
}
.btn.btn-clear, .btn.btn-clear:focus {
  /* background-color: #1B4B82;
  border-color: #1B4B82 !important; */
  background-color: #0089FF;
  border-color: #0089FF !important;
  color: #fff;
  border-radius: "5px";
  width: 92px;
  height: 33px;
}
.btn.btn-clear:hover, .btn.btn-clear:focus:hover {
  /* background-color: #1B4B82;
  border-color: #1B4B82; */
  background-color: #0089FF;
  border-color: #0089FF;
  width: 92px;
  height: 33px;
}
.btn.btn-upload, .btn.btn-upload:focus {
  /* background-color: #1B4B82;
  border-color: #1B4B82 !important; */
  background-color: #959799;
  border-color: #959799!important;
  color: #fff;
  width: 121px;
  border-radius: "5px";
}
.btn.btn-upload:hover, .btn.btn-upload:focus:hover {
  /* background-color: #1B4B82;
  border-color: #1B4B82; */
  background-color: #959799;
  border-color: #959799;
  width: 121px;
}
.btn.btn-primary.bordered, .btn.btn-primary:focus.bordered {
  background-color: transparent;
  color: #028394;
}
.btn.btn-primary.bordered:hover, .btn.btn-primary:focus.bordered:hover {
  background-color: #028394;
  color: #fff;
}
.btn.btn-info, .btn.btn-info:focus {
  background-color: #0ab1fc;
  border-color: #03a9f4 !important;
  color: #fff;
}
.btn.btn-info:hover, .btn.btn-info:focus:hover {
  background-color: #06a3d2;
  border-color: #269abc;
}
.btn.btn-info.bordered, .btn.btn-info:focus.bordered {
  background-color: transparent;
  color: #03a9f4;
}
.btn.btn-info.bordered:hover, .btn.btn-info:focus.bordered:hover {
  background-color: #03a9f4;
  color: #fff;
}
.btn.btn-success, .btn.btn-success:focus {
  background-color:#28876E;
  border-color: #28876E !important;
  color: #fff;
}
.btn.btn-success:hover, .btn.btn-success:focus:hover {
  background-color: #2d942d;
  border-color: #2d942d;
}
.btn.btn-success.bordered, .btn.btn-success:focus.bordered {
  background-color: #E7FFDB;
  color: #7FB679;
  border-color: #9CD898!important;
}
.btn.btn-success.bordered:hover, .btn.btn-success:focus.bordered:hover {
  background-color: #8bc34a;
  color: #fff;
}
.btn.btn-dark-green.bordered{
  border-color: #64702F;
  color: #64702F;
  background: #F1FCCB;
}
.btn.btn-dark-green.bordered:hover,.btn.btn-dark-green.bordered:focus{
  border-color: #64702F;
  color: #F1FCCB;
  background:#64702F ;
}

.btn.btn-mint, .btn.btn-mint:focus {
  background-color: #29b2a6;
  border-color: #26a69a !important;
  color: #fff;
}
.btn.btn-mint:hover, .btn.btn-mint:focus:hover {
  background-color: #1da79a;
  border-color: #29b2a6;
}
.btn.btn-mint.bordered, .btn.btn-mint:focus.bordered {
  background-color: transparent;
  color: #26a69a;
}
.btn.btn-mint.bordered:hover, .btn.btn-mint:focus.bordered:hover {
  background-color: #26a69a;
  color: #fff;
}
.btn.btn-warning, .btn.btn-warning:focus {
  background-color: #ffb80f;
  border-color: #ffb300 !important;
  color: #fff;
}
.btn.btn-warning:hover, .btn.btn-warning:focus:hover {
  background-color: #fbb100;
  border-color: #d58512;
}
.btn.btn-warning.bordered, .btn.btn-warning:focus.bordered {
  background-color: transparent;
  color: #ffb300;
}
.btn.btn-warning.bordered:hover, .btn.btn-warning:focus.bordered:hover {
  background-color: #ffb300;
  color: #fff;
}
.btn.btn-danger, .btn.btn-danger:focus {
  background-color: #FF0000;
  border-color: #f44336 !important;
  color: #fff;
  width: 121px;
  border-radius: 5px;
}
.btn.btn-danger1, .btn.btn-danger1:focus {
  background-color: #FF0000;
  border-color: #f44336 !important;
  color: #fff;
 
  border-radius: 5px;
}
.btn.btn-danger:hover, .btn.btn-danger:focus:hover {
  background-color: #FF0000;
  border-color: #FF0000;
  width: 121px;
  border-radius: 5px;
}
.btn.btn-danger.bordered, .btn.btn-danger:focus.bordered {
  background-color: transparent;
  color: #f44336;
}
.btn.btn-danger.bordered:hover, .btn.btn-danger:focus.bordered:hover {
  background-color: #f44336;
  color: #fff;
}
.btn.btn-pink, .btn.btn-pink:focus {
  background-color: #f1709c;
  border-color: #f06292 !important;
  color: #fff;
}
.btn.btn-pink:hover, .btn.btn-pink:focus:hover {
  background-color: #f14b84;
  border-color: #f1709c;
}
.btn.btn-pink.bordered, .btn.btn-pink:focus.bordered {
  background-color: transparent;
  color: #f06292;
}
.btn.btn-pink.bordered:hover, .btn.btn-pink:focus.bordered:hover {
  background-color: #f06292;
  color: #fff;
}
.btn.btn-purple, .btn.btn-purple:focus {
  background-color: #b052c0;
  border-color: #ab47bc !important;
  color: #fff;
}
.btn.btn-purple:hover, .btn.btn-purple:focus:hover {
  background-color: #a638b9;
  border-color: #b052c0;
}
.btn.btn-purple.bordered, .btn.btn-purple:focus.bordered {
  background-color: transparent;
  color: #ab47bc;
}
.btn.btn-purple.bordered:hover, .btn.btn-purple:focus.bordered:hover {
  background-color: #ab47bc;
  color: #fff;
}
.btn.btn-dark, .btn.btn-dark:focus {
  background-color: #414c57;
  border-color: #3a444e !important;
  color: #fff;
}
.btn.btn-dark:hover, .btn.btn-dark:focus:hover {
  background-color: #323e4a;
  border-color: #414c57;
}
.btn.btn-dark.bordered, .btn.btn-dark:focus.bordered {
  background-color: transparent;
  color: #3a444e;
}
.btn.btn-dark.bordered:hover, .btn.btn-dark:focus.bordered:hover {
  background-color: #3a444e;
  color: #fff;
}
.btn.btn-sm {
  padding: 0.2rem 0.5rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .btn.btn-sm {
    padding: 0.2rem 0.4rem;
  }
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 102px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}



a.btn:not([href]):not([tabindex]) {
  color: #fff;
}

@media screen and (min-width: 992px) {
  .multi-control-form .control-label {
    text-align: right;
  }
}
.fixed-topmenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-flow: row;
  /* padding-left: 52px; */
  align-items: center;
  background: #E9EEFF;
  /* box-shadow: 4px 0px 5px #d3e0e8; */
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.toggoleIcon{
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.fixed-topmenu .navbar-brand{display:none}
@media screen and (min-width: 992px) {
  .fixed-topmenu {
    position: fixed;
    background: #ffffff;
     padding-right: 14px; 
    height: 47px;
    -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
    /* justify-content: space-between; */
  }

  .fixed-topmenu .navbar-brand {
    display: block;
    width: 200px;
    -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
  .fixed-topmenu .nav-search {
    -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
    width: calc(100% - 1080px)
    
}
  .fixed-topmenu .nav-search .form-control{
    background:#FFFFFF;
    border-color:#D4D4D4 
    /* background:#F0F3F5;
    border-color:#F0F3F5 */
}

.fixed-topmenu .navbar-brand img{
   height: 36px;
}
}
@media screen and (min-width: 1500px) {
  .fixed-topmenu {
  }
}
.fixed-topmenu ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 835px;
}
.fixed-topmenu ul li {
  position: relative;
  display: block;
}
.fixed-topmenu ul li a {
  /* padding: 0.7rem 0.4rem; */
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  color: #646479;
}
.fixed-topmenu ul li a small{
  font-size:.9rem;
  color:#414D55;
  display: block;
}
.fixed-topmenu ul li a i {
  font-size: 1.3rem;
}


.fixed-topmenu ul li a .badge {
  margin-top: -10px;
  position: absolute;
  left: -2px;
}

.fixed-topmenu ul li a.menu-profile i{
font-size: .9rem;
    padding: 3px;
    border-radius: 3px;
    background: #ededed;
    margin-left: 0.5rem;
}

.fixed-topmenu ul li a img {
  height: 25px;
  margin-right: 6px;
}
.fixed-topmenu ul li .header-dropdown {
  display: none;
  position: absolute;
  /* background: linear-gradient(180deg, #7685FC 40.46%, #D814F3 168.6%); */
  background: #000000;
  /* border-radius: 5px; */
  box-shadow: 0px 0px 4px #b3b3b3;
  right: 0;
  min-width: 10rem;
}
.fixed-topmenu ul li .header-dropdown a {
  padding: 0.4rem 0.7rem;
  border-bottom: 0px solid #e4e4e4;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}
.fixed-topmenu ul li .header-dropdown a:hover {
  color: #1E1E1E;
  background-color: #efeaea;
  /* margin: 5px; */
  /* width: 150px; */
  /* border-radius: 5px; */
  font-size: 14px;
  font-weight: 600;
}
.fixed-topmenu ul li:hover .header-dropdown {
  display: block;
}

.notification-sec {
  overflow: auto;
  padding: 1rem;
  background: #fff;
  border-radius: 0;
  margin-top: 0;
  position: fixed;
  z-index: 999;
  right: -100vw;
  width: calc(100vw - 80px);
  top: 0;
  bottom: 0;
  box-shadow: 0 0 5px #bbb;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.notification-sec.active {
  right: 0px;
}
@media screen and (min-width: 768px) {
  .notification-sec.active {
    left: auto;
  }
}
.notification-sec h5 {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #000;
  font-weight: 600;
}
.notification-sec ul li {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
  position: relative;
  line-height: 1.4;
}
.notification-sec ul li:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #dedede;
  top: 8px;
  left: 0;
}
.notification-sec ul li.unread {
  color: #26826e;
}
.notification-sec ul li.unread:before {
  background: #1a4a7e;
}
@media screen and (min-width: 768px) {
  .notification-sec {
    right: -400px;
    width: 220px;
    left: auto;
  }
}

.nav-toggle-btn {
  position: fixed;
  height: 22px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 0.2rem 0;
  z-index: 999;
  overflow: hidden;
  top: 12px;
  left: 1rem;
}
.nav-toggle-btn span {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  height: 2px;
  width: 23px;
  display: block;
  border-radius: 2px;
  background: #747576;
}
.nav-toggle-btn span:nth-child(2){
  width: 15px;
}
@media screen and (min-width: 992px) {
  .nav-toggle-btn {
    position: absolute;
    top: 12px;
    left: 230px;
  }
}
@media screen and (min-width: 1500px) {
  .nav-toggle-btn {
    /* top: 26px; */
    /* left: 2rem; */
  }
}

.card {
  position: relative;
  box-shadow: 0 4px 10px #e5ebef;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #fff;
}

.page-container {
 
  position: relative;
  margin-top: 0;
  margin-right: 0;
}
.page-container:before {
 /* content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 9999;*/
}
.page-container .overlay {
 visibility: visible;

}
.page-container.display-full .overlay {
 visibility: hidden;

}


@media screen and (min-width: 992px) {
  .page-container:before {
    position: absolute;
    z-index: -1;
  }
}
.page-container .sidemenu {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: -275px;
  width: 220px;
  display: block;
  z-index: 99;
  padding: 1rem;
  font-weight: 200;
  border-radius: 0;
  background: #ffffff;
box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.15);

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
}
.page-container .sidemenu.active {
  left: 0;
  z-index: 99999;
}
.page-container .sidemenu.active .overlay {
 visibility: visible;
}
@media screen and (min-width: 992px) {
  .page-container .sidemenu {
    top: 47px;
    left: 0;
    box-shadow: 0px 3px 5px #d3e0e8;
  }

}
.page-container .mainpanel {
  /* background: #F1F1F1; */
  position: relative;
  max-width: 100%;
  background-size: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding-top: 3rem;
}
.page-container.smooth{
  position: relative;
  max-width: 100%;
  background-size: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /* padding-top: 3rem;
  padding-left: 40rem !important;
  margin-left:40rem !important; */

}
@media screen and (min-width: 992px) {
  .page-container .mainpanel {
    margin-left: 220px;
    overflow: hidden;
    overflow-y: auto;
    padding: 46px 0rem 0;
    height: 100vh;
  }
}
@media screen and (min-width: 1440px) {
  .page-container .mainpanel {
  }
}
@media screen and (min-width: 1500px) {
  .page-container .mainpanel {
    /* padding: 47px 6rem 0; */
  }
}
.page-container.display-full .mainpanel {
  margin-left: 0;
}
.page-container.display-full .sidemenu {
  left: -275px;
  box-shadow: none;
  z-index: -1;
}
@media screen and (min-width: 992px) {
  .page-container.display-full .sidemenu .mainpanel {
    max-width: 100%;
    margin-left: 0;
  }
}
.page-container.display-full:before {
  z-index: 0;
  bottom: auto;
}
@media screen and (min-width: 992px) {
  .page-container.display-full .fixed-topmenu {
    left: 0;
  }
}
@media screen and (min-width: 992px) {
  .page-container {
    height: 100vh;
    overflow: hidden;
  }
}

@media screen and (min-width: 992px) {
  /* width */
  .mainpanel::-webkit-scrollbar,.surgeon-list::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .mainpanel::-webkit-scrollbar-track , .surgeon-list::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px grey;
    border-radius: 0px;
  }

  /* Handle */
  .mainpanel::-webkit-scrollbar-thumb ,.surgeon-list::-webkit-scrollbar-thumb{
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0px;
  }

  /* Handle on hover */
  .mainpanel::-webkit-scrollbar-thumb:hover ,.surgeon-list::-webkit-scrollbar-thumb:hover{
    background: #1e1e2d;
  }
}
.theme-seeting {
  position: absolute;
  bottom: 2.5rem;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
}
.theme-seeting ul {
  margin-bottom: 0;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.theme-seeting ul li a {
  border-radius: 3px;
  transition: all ease-in-out 0.4s;
  position: relative;
  padding: 0.7rem 1.5rem;
  margin-left: 0.2rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.theme-seeting ul li a.active {
  fill: #1e1e2d;
}
@media screen and (min-width: 992px) {
  .theme-seeting ul li a.active {
    fill: #fff;
    background: #1e1e2d;
  }
}
.theme-seeting ul li a:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.15);
}

.sidemenu .navbar-brand {
  display: block;
  text-align: center;
  margin: 0;
  display: flex;
  flex-flow: column;
  border-bottom: 0px solid #272727;
  padding: 0rem 0rem;
  align-items: center;
}
.sidemenu .navbar-brand img {
  max-width: fit-content;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .sidemenu .navbar-brand img {
    max-width: fit-content;
  }
   .sidemenu .navbar-brand{
    display:none;
  }
}
.sidemenu .navbar-brand h2 {
  white-space: break-spaces;
  line-height: 1.6rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 1rem;
  color: #fff;
}
.sidemenu .navbar-brand p {
  margin-bottom: 0;
  font-size: 95%;
  color: #cacaca;
}
.sidemenu .navbar {
  padding: 0;
}
.sidemenu .navbar .navbar-nav .nav-item {
  margin-bottom: 1px;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link {
  padding: 0.6rem 1rem;
  color: #000000;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 0px solid rgba(0, 0, 0, 0);
  padding-left: 1.5rem;
  font-weight: 600;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link span {
  display: inline-block;
  font-size: 1.1rem;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link i {
  margin-right: 1rem;
  display: inline-block;
  color: #8585ad;
  font-size: 1.6rem;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link i span {
  font-size: 1.7rem;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link:hover, .sidemenu .navbar .navbar-nav .nav-item .nav-link:focus {
  color: #158f78;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link:hover i, .sidemenu .navbar .navbar-nav .nav-item .nav-link:focus i {
  color: #3699ff;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle {
  position: relative;
}
.sidemenu .navbar .navbar-nav .nav-item .nav-link.dropdown-toggle:after {
  content: "\e940";
  position: absolute;
  left: 2px;
  font-family: icons;
  font-size: 1rem;
  top: 50%;
  transition: all ease-in-out 0.2s;
  transform: translateY(-50%);
}

@media screen and (min-width: 992px) {
  
}
.sidemenu .navbar .navbar-nav .nav-item.active .nav-link {
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  border-left-color: #ff9800;
}
.sidemenu .navbar .navbar-nav .nav-item.active .nav-link i {
  color: #3699ff;
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 0;
  opacity: 0;
  position: relative;
  display: none;
  border-bottom: 0px solid #f2f2f2;
  border-left: 0px solid #ff9800;
  overflow: hidden;
}


.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item {
  /* padding: 0.4rem 1rem; */
  margin-bottom: 0;
  position: relative;
  font-weight: 600;
  font-size: 16px;
   /* color: #8DA1B5;  */
   color: #5d6976;
  border-radius: 0;
  border-left: 0px solid transparent;
  border-radius: 4px;
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item i{
  margin-right:.2rem
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:before {
  width: 0px;
  height: 0px;
  position: absolute;
  border-radius: 100%;
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:hover, .sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item:focus {
  color: #000;
  background: #fff;
  
  /* background: linear-gradient(89.86deg, #fff -43.57%, #ffff 148.52%); */
  /* background: transparent linear-gradient(93deg, #1A497F 0%, #27836E 100%) 0% 0% no-repeat padding-box; */
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item.active {
  color: #000;
  background: #ffffff;
  background: linear-gradient(89.86deg, #fff);
  /* color: #ffffff;
  background: #249a82;
  background: transparent linear-gradient(93deg, #1A497F 0%, #27836E 100%) 0% 0% no-repeat padding-box; */
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu.show {
  display: inherit !important;
  height: auto;
  opacity: 1;
}
.sidemenu .navbar .navbar-nav .nav-item .dropdown-menu .dropdown-item{
  position: relative;
  /* margin-bottom: .1rem; */
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 10px;
}
.sidemenu .submenu .dropdown-menu{
  margin-left: 1rem!important;
}
.sidemenu .submenu:hover .dropdown-menu{
  display: inherit !important;
  height: auto;
  opacity: 1!important;
}
.dropend .dropdown-toggle::after {
  all: unset;
  content: "\e944";
  position: absolute;
  right:8px;
  font-family: icons;
  font-size: 1rem;
  top: 50%;
  transition: all ease-in-out 0.2s;
  transform: translateY(-50%);
}
.dropend .dropdown-toggle.show::after {
  transform: rotate(
90deg) translateY(106%) translateX(-48%);
  right:0
}
.dropend .dropdown-menu[data-bs-popper] {
  left: 0rem;
  min-width: 4rem;
  right: 0;
  margin: 0;
  padding-left: 1rem!important;
  
}
.common-btn1{
  color: #ffffff;

background-color: #ebebeb;
-webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

/* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.08); */
border-radius: 4px;
  padding: .3rem 1rem;
  font-size: inherit;
  transition:all ease-in-out .5s;
}
.common-btn1:hover{
  color: #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

 
  
  /* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08); */
  border-radius: 4px;
/* box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.15); */
  
}
.common-btn2{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #FFF;
  color: #747576;
}
.common-btn{
  color: #ffffff;
  background:#000000;


box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
border-radius: 4px;
  padding: .3rem 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition:all ease-in-out .5s;
}
.common-btn:hover{
  color: #FFFFff;
 
  background:#000000;
 
  
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.15);
  
}
@media screen and (min-width: 992px) {
  .sidemenu .navbar .navbar-nav .nav-item .dropdown-menu {
    display: none;
  }
}
.sidemenu .navbar .navbar-nav .nav-item.dropdown.show {
  background: rgba(242, 242, 242, 0);
}
.sidemenu .navbar .navbar-nav .nav-item.dropdown.show .nav-link {
  color: #ececec;
  background: rgba(0, 0, 0, 0.3);
  border-left-color: #ff9800;
}
.sidemenu .navbar .navbar-nav .nav-item.dropdown .nav-link.dropdown-toggle.show:after {
  transform: rotate(272deg) translateY(-90%) translateX(46%);
  left: 15px;
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background: transparent;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.03), 0 3px 1px -2px rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #646479;
}
footer p {
  margin-bottom: 0;
  font-size: 1rem;
}

table.responsive-table.vertical-table thead,
table.responsive-table.vertical-table tbody,
table.responsive-table.vertical-table th,
table.responsive-table.vertical-table td,
table.responsive-table.vertical-table tr, table.responsive-table.vertical-table {
  display: block;
  overflow: hidden;
  clear: left;
  width: 100%;
}

.user-profile {
  /* background: #1e1e2d url(../images/pattren1.png) no-repeat; */
  background-size: 65%;
  background-position: bottom left;
  padding: 7rem 1rem 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  transition: all ease-in 1s;
  border-radius: 4px;
  margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
  .user-profile {
    position: relative;
    align-items: center;
    flex-flow: column;
    padding: 1rem;
    border-radius: 4px;
  }
}
.user-profile img {
  height: 65px;
  margin-bottom: 1rem;
  vertical-align: top;
  transition: all ease-in 1s;
  position: absolute;
  right: 50%;
  top: 1.5rem;
  transform: translateX(50%);
}
@media screen and (min-width: 992px) {
  .user-profile img {
    top: 0rem;
    position: relative;
    transition: none;
    right: auto;
    transform: inherit;
  }
}
.user-profile h6 {
  color: #fff;
  font-weight: 600;
  transition: all ease-in-out 0.1s;
  font-size: 1.2rem;
}
.user-profile h6 small {
  display: block;
  color: #fff;
}
.user-profile.active {
  background: transparent;
  padding: 1rem;
  border-bottom: 0;
}
.user-profile.active img {
  height: 24px;
  margin-bottom: 0;
  position: fixed;
  right: 75px;
  top: 1rem;
  z-index: 10;
}
.page-controls-section {
  /* padding: 0 1rem; */
  transition: all ease-in 1.2s;
}
.page-controls-section.active {
  transition: all ease-out 0.1s;
}
@media screen and (min-width: 768px) {
  .page-controls-section {
    /* padding: 0 1rem; */
  }
}
@media screen and (min-width: 992px) {
  .page-controls-section {
    /* margin-top: 0; */
    /* padding: 0; */
    /* padding-top: 0; */
    /* position: relative; */
    width: 100%;
  }
}
.page-controls-section h3 {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.no-content {
  text-align: center;
  color: #d4d4d4;
  padding: 3rem 1rem;
  text-transform: uppercase;
  font-size: 1.4rem;
}
@media screen and (min-width: 992px) {
  .no-content {
    padding: 4rem 1rem;
    font-size: 1.8rem;
  }
}

.card {
  margin-top: 0.1rem;
  border: 0px;
  padding: 0;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .card {
    margin-bottom: 1.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .card {
    margin-bottom: 1.5rem;
    min-height: 90vh;
  }
}
.card .card-header {
  padding: 1rem;
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom: 0;
}
@media screen and (min-width: 992px) {
  .card .card-header {
    padding: 0.5rem 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .card .card-header {
    padding: 1rem 1.5rem;
  }
}
.card .card-header h5 {
  font-size: 1.2rem;
  margin-bottom: 0;
  text-transform: capitalize;
  color: #000;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .card .card-header h5 {
  }
}
.card .card-body {
  padding: 1rem;
  padding-top: 0;
}
@media screen and (min-width: 992px) {
  .card .card-body {
    padding: 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .card .card-body {
    padding: 1.5rem;
  }
}
.card .card-footer {
  padding: 0.5rem 1rem;
  background: #fff;
}
@media screen and (min-width: 992px) {
  .card .card-footer {
    padding: 0.75rem 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .card .card-footer {
    padding: 1rem 1.5rem;
  }
}
.card.fixed-height {
  height: 400px;
}

.page-title {
  padding: 0.5rem 1rem;
}
@media screen and (min-width: 992px) {
  .page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .6rem 1rem;
    margin-left: -1rem;
    margin-bottom: 0rem;
    transition: all ease-in-out 0.3s;
    /* background: #fff; */
  }
}
@media screen and (min-width: 1440px) {
  .page-title {
  }
}
@media screen and (min-width: 1500px) {
  .page-title {
    color: #000;
  }
}
.page-title h4 {
  /* font-size: 1.4rem; */
  font-size: 2rem;
  margin-bottom: 0;
  /* color: #268f6f; */
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .page-title h4 {
    margin-bottom: 0;
    /* color: #268f6f; */
  }
}
@media screen and (min-width: 1500px) {
  .page-title h4 {
    /* font-size: 1.4rem; */
    font-size: 2rem;
    /* color: #268f6f; */
    color: #000;
  }
}
.page-title .breadcrumb {
  display: none;
  background: transparent;
  padding: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .page-title .breadcrumb {
    display: block;
    margin-left: 1rem;
  }
}
.page-title .breadcrumb .breadcrumb-item {
  font-size: 1rem;
}
@media screen and (min-width: 992px) {
  .page-title .breadcrumb .breadcrumb-item {
    font-size: 1.1rem;
    display: inline-block;
  }
}
@media screen and (min-width: 992px) {
  .page-title .breadcrumb .breadcrumb-item.active a {
    font-weight: 600;
  }
}

.nav-tabs {
  margin-bottom: 0;
  width: 100%;
  padding: 0.5rem 1rem 0;
}
@media screen and (min-width: 992px) {
  .nav-tabs {
    float: none;
    margin-bottom: 0;
    border-bottom: 0;
    width: 100%;
    padding: 0rem 0;
  }
}
@media screen and (min-width: 1440px) {
  .nav-tabs {
  }
}
.nav-tabs .nav-link {
  color: #181818;
  padding: 0.4rem 1.5rem;
  display: inline-block;
  border: 1px solid #f1f1f1;
  position: relative;
  flex: initial;
  margin-right: 0.2rem;
  background: #E5E5E5;
  border-top-width: 1px;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 7px 7px 0 0;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .nav-tabs .nav-link {
    margin-right: 0rem;
    padding: 0.4rem 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .nav-tabs .nav-link {
    /* padding: 0.7rem 2rem; */
    /* font-size: 1rem; */
  }
}
.nav-tabs .nav-link:hover {
  background: transparent;
  color: #000;
  border-color: transparent;
}
.nav-tabs .nav-link.show, .nav-tabs .nav-link.active {
  color: #000;
  background-color: #fff;
  border-color: #ffffff;
  border-bottom-color: #fff;
  box-shadow: none;
}

.indicatorslist {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0.2rem 1rem;
}
@media screen and (min-width: 992px) {
  .indicatorslist {
    flex: 0 0 50%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 0;
    padding: 0;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .indicatorslist {
    padding: 0rem;
    padding-right: 1.5rem;
  }
}
.indicatorslist a {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  margin-left: 7px;
  color: #3699ff;
  font-size: 1.3rem;
  border: 0px solid #e4e4e4;
  border-radius: 3px;
  line-height: 0.9rem;
  box-shadow: 0px 1px 5px #d6dfe4;
}
.indicatorslist a#searchicon {
  color: #3699ff;
}
.indicatorslist a#printicon {
  color: #28a745;
}
.indicatorslist a#deleteicon {
  color: #dc3545;
}
.indicatorslist a#downloadicon {
  color: #17a2b8;
}
.indicatorslist a:hover {
  color: #1e1e2d;
  border-color: #1e1e2d;
  box-shadow: 0px 1px 5px #95b6d8;
}
@media screen and (min-width: 992px) {
  .indicatorslist a {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1440px) {
  .indicatorslist a {
    padding: 0.4rem 0.5rem;
    font-size: 1.3rem;
  }
}
.indicatorslist p {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.1em;
  padding: 0.35rem 0;
}

.instruction-details {
  height: 400px;
  overflow: auto;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 10px;
  margin-top: 1rem;
}
.instruction-details h5 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #000;
}
.instruction-details ul li {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
  position: relative;
}
.instruction-details ul li:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #dedede;
  top: 8px;
  left: 0;
}
@media screen and (min-width: 992px) {
  .instruction-details {
    height: 76vh;
    margin-top: 0;
  }
}

.search-container {
  position: relative;
  background: #f0f5f8;
  border-radius: 4px;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
}
.search-container .search-sec {
  position: relative;
  transition: all ease-in-out 0.5s;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .search-container .search-sec {
    transition: all ease-in-out 0.5s;
    display: block;
    height: auto;
    padding: 1rem;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1440px) {
  .search-container .search-sec {
    padding: 1.5rem;
    padding-bottom: 0;
  }
}
.search-container .search-sec.open {
  margin-bottom: 0;
  padding: 1rem 1rem 0;
  height: auto;
  transition: all ease-in-out 0.5s;
}
@media screen and (min-width: 992px) {
  .search-container .search-sec.open {
    padding: 1.5rem 1.5rem 0.5rem;
    margin-bottom: 1.5rem;
  }
}
.search-container .searchopen {
  border-top: 0px solid #e4e4e4;
  height: 26px;
  text-align: center;
  background: #f0f5f8;
  position: absolute;
  right: 1rem;
  margin-top: -25px;
  line-height: 1;
  font-size: 1.4rem;
}
.search-container .searchopen i {
  color: #1e1e2d;
}
@media screen and (min-width: 992px) {
  .search-container .searchopen {
    margin-top: -28px;
    display: none;
  }
}
.search-container.active {
  height: auto;
  margin-bottom: 1rem;
}

table tr th {
  padding: 0.25rem 0.3rem;
  /* border-width: 1px; */
}
table tr td {
  padding: 0.4rem 0.5rem;
}
table tr td a {
  color: #000000;
  display: inline-block;
  margin-right: 0.3rem;
}
table tr td a i {
}
table tr td a:not([href]):not([tabindex]) {
  color: #828282;
}
table tr td a.delete {
  color: #e91e63 !important;
}
table tr td a.edit {
  color: #00BCD4 !important;
}
table tr td a.success {
  color: #009688 !important;
}
table tr td a:hover, table tr td a:focus {
  color: #02876c !important;
}
table tr input[type=checkbox] + label:before,
table tr input[type=radio] + label:before {
  top: -4px;
}
table input[type=checkbox] + label:after {
  top: -4px;
}
table.table {
  margin-bottom: 1rem;
  font-size:1rem
}
table.table thead th {
  border-bottom: 1px solid #ececec;
}
table.table tr {
  transition: all ease-in-out 0.3s;  
}
table.table tr:nth-of-type(odd),
table.table tr:hover:nth-of-type(odd) {
  background-color: #FFFFFF;
  border-bottom: none;
}
table.table tr:nth-of-type(even),
table.table tr:hover:nth-of-type(even) {
  background-color: #F4F4F4;
  border-bottom: none;
}
table.table tr:hover {
  background-color: #D4D4D4;
  border-bottom: none;
}

.table>:not(caption)>*>* {
    padding: 0.4rem 0.3rem;
    vertical-align: middle;
}
table.table tr td {
  border-color: #ececec;
}
table.table tr:last-child td {
  border-bottom: 0px solid #ececec;
  
}
@media screen and (min-width: 768px) {
  table.table {
    margin-bottom: 0rem;
  }
}
table.table-bordered {
  border: 0;
}
table.table-hover tbody tr:hover {
  background-color: #f3f3f3;
  box-shadow: 0px 2px 3px #a0a0a0;
}
table.hover-table tr {
  position: relative;
  display: flex;
  width: 100%;
}
table.hover-table tr th {
  flex: 1;
}
table.hover-table tr td {
  flex: 1;
}
table.hover-table tr td.hover-td {
  display: none;
  position: absolute;
  right: 0;
  background: #f3f3f3;
  justify-content: flex-end;
  padding: 0 2rem;
  align-items: center;
  height: 40px;
  border: 0;
  transition: all ease-in-out 0.5s;
}
table.hover-table tr:hover td.hover-td {
  display: flex;
  transition: all ease-in-out 0.5s;
}
table.hover-table .tx {
  flex: 0 0 100px;
}
table.hover-table .tx2 {
  flex: 0 0 50px;
}
table.hover-table .tx3 {
  flex: 0 0 70px;
}
table.valign-middle td {
  vertical-align: middle;
}
table.responsive-table.vertical-table thead tr {
  display: none;
}
table.responsive-table.vertical-table tr {
  margin-bottom: 1rem;
  border: 1px solid #e8e8e8;
}
table.responsive-table.vertical-table tbody div.responsive-container {
  display: block;
}
table.responsive-table.vertical-table td {
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  display: flex;
}
table.responsive-table.vertical-table td:before {
  position: relative;
  width: 40%;
  padding-right: 10px;
  font-weight: bold;
  word-break: break-all;
}

.pagination {
  margin-bottom: 0;
}
.pagination .page-item .page-link {
  color: #7e8299;
  border: 0;
  border-radius: 4px;
  margin-right: 5px;
}
.pagination .page-item .page-link:focus, .pagination .page-item .page-link:hover {
  color: #000;
  box-shadow: none;
}
.pagination .page-item.active .page-link {
  z-index: 2;
  color: #f0f5f8;
  cursor: default;
  background-color: #3699ff;
  border-color: transparent;
}
.pagination .page-item:first-child .page-link {
  background: #ecf1f6;
}
.pagination .page-item:last-child .page-link {
  background: #ecf1f6;
}
.pagination .page-item:not(:first-child):not(:last-child) .page-link {
  display: none;
}
@media screen and (min-width: 992px) {
  .pagination .page-item:not(:first-child):not(:last-child) .page-link {
    display: block;
  }
}

.form-heading {
  font-size: 1.2rem;
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #232424;
}

.accordion {
  margin-bottom: .5rem;
  border: 0px solid #f0f5f8;
  background: #fff;
}
.accordion .accordion-header {
  background: #ffffff;
  position: relative;
}
.accordion .accordion-header h5 {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #565656;
  background: #EAEAEA;
  border-radius: 6px;
}
.accordion .accordion-header h5 a {
  display: block;
}
.accordion .accordion-header h5 a:after {
  position: absolute;
  right: 1rem;
  font-family: icons;
  content: "\e944";
}
.accordion .accordion-header h5 a.collapsed:after {
  content: "\e946";
}
.accordion .accordion-header h4 {
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #000;
  background: #EAEAEA;
  border-radius: 6px;
}
.accordion .accordion-header h4 a {
  display: block;
}
.accordion .accordion-header h4 a:after {
  position: absolute;
  right: 1rem;
  font-family: icons;
  content: "\e940";
  font-size: 1.1rem;
  top: 11px

}
.accordion .accordion-header h4 a.collapsed:after {
  content: "\e944";
}
.accordion .accordion-body {
  padding: 1rem;
}

.controls-section {
  padding: 0 0;
}
.controls-section h5 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding-right: 2rem;
}
@media screen and (min-width: 992px) {
  .controls-section h5 {
    font-size: 1.4rem;
  }
}

.controls-section-header {
  display: flex;
  border-bottom: 0px solid #e4e4e4;
  align-items: flex-start;
  padding: 0;
  flex-flow: column;
}
@media screen and (min-width: 992px) {
  .controls-section-header {
    border-bottom: 1px solid #e4e4e4;
    align-items: center;
    padding: 0;
    flex-flow: row;
  }
}

.form-control-static {
  font-weight: 600;
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
}

.profile-hilights {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #d4d4d4;
  padding-bottom: 1rem;
}
.profile-hilights i {
  color: #1e1e2d;
  margin-right: 1rem;
  font-size: 1.4rem;
}
@media screen and (min-width: 992px) {
  .profile-hilights i {
    font-size: 1.4rem;
    margin-right: 1rem;
  }
}
@media screen and (min-width: 1440px) {
  .profile-hilights i {
    font-size: 1.8rem;
    margin-right: 1.5rem;
  }
}
.profile-hilights p {
  margin-bottom: 0;
}
.profile-hilights p span {
  color: #a9a9a9;
}

.separator {
  height: 0;
  margin-bottom: 2rem;
}
.separator.separator-dashed {
  border-bottom: 1px dashed #bdc2c5;
}

.form-group .form-text {
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 400;
  color: #5d5d5d;
}
.form-group .form-text.danger {
  color: #dc3545;
}
.form-group .form-text.success {
  color: #28a745;
}

.help-block {
  margin-top: 8px;
  display: block;
  letter-spacing: 1px;
}

.text-muted {
  color: #b5b5c3 !important;
}

.modal .modal-dialog {
  border: 0px solid #1e1e2d;
  border-radius: 7px;
}
.modal-header .btn-close {
       padding: 0.7rem;
       /* background-color: #27a9b5; */
        background-color: #7685FC;
       border-radius: 0 4px;
       color: #fff;
       opacity: 1;
       position: absolute;
       right: .5rem;
       top: .45rem;
}


.modal .modal-header {
  padding: 0.5rem 1rem;
      border-bottom: 0px solid #dee2e6;
}
.modal .modal-footer {
  padding: 0.5rem 1rem;
}
.modal .modal-title {
     font-size: 1.6rem;
    color: #4E5A59;
    font-weight: 600;
}

figure {
  display: block;
  background: #f3f3f3;
  padding: 1rem;
  border-radius: 3px;
  margin: 1rem 0;
  margin-right: 0;
  margin-left: 0;
}

pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  overflow: initial;
  border: 0;
}

code {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.s {
  color: #d44950;
}

.na {
  color: #4f9fcf;
}

.nt {
  color: #2f6f9f;
}

code.highlighter-rouge {
  color: #d44950;
}

alert-box {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  box-sizing: border-box;
  max-width: 500px;
  overflow: auto;
}
alert-box[position=top-left] {
  right: auto;
  left: 0;
}
alert-box[position=bottom-left] {
  right: auto;
  left: 0;
  top: auto;
  bottom: 0;
}
alert-box[position=bottom-right] {
  right: 0;
  left: auto;
  top: auto;
  bottom: 0;
}

alert:before, alert:after {
  content: "";
  position: absolute;
  background-color: #aaa;
  opacity: 0.8;
  transform: rotate(45deg);
}

alert {
  display: block;
  position: relative;
  font-size: 1.1rem;
  padding: 1rem 2.5rem 1rem 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 0 3px 5px #aaa;
  letter-spacing: 1px;
  border-radius: 2px;
}
alert:before {
  width: 16px;
  height: 2px;
  box-sizing: border-box;
  right: 14px;
  top: calc(50% - 1px);
}
alert:after {
  width: 2px;
  height: 16px;
  box-sizing: border-box;
  right: 21px;
  top: calc(50% - 8px);
}
alert[fade-on-hover=true]:hover {
  opacity: 0.5;
}
alert[close-on-click=true] {
  cursor: pointer;
}
alert[type=success] {
  color: #28a745;
}
alert[type=error] {
  color: #f00;
}
alert[type=warning] {
  color: #fd7e14;
}
alert[type=notification] {
  color: #007bff;
}
alert[mode=dark] {
  color: #fff;
}
alert[mode=dark]:before, alert[mode=dark]:after {
  background-color: #fff;
}
alert[type=success][mode=dark] {
  background-color: #28a745;
}
alert[type=error][mode=dark] {
  background-color: #f00;
}
alert[type=warning][mode=dark] {
  background-color: #fd7e14;
}
alert[type=notification][mode=dark] {
  background-color: #007bff;
}
alert[corners=round] {
  border-radius: 4px;
}

.alert-close {
  position: absolute;
  display: block;
  right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  top: calc(50% - 10px);
  z-index: 1;
}

.two-sections{
  display: flex;
   flex-wrap: wrap;
  justify-content: space-between;
}
.two-sections .section-one{
  flex: 0 0 calc(100% - 230px );
  padding:.4rem .5rem;
}

.two-sections .section-two{
  flex: 0 0 230px;
  padding: .5rem 0;
  background: #f0f5f8;
}

.three-sections{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.three-sections .section-one{
  flex: 0 0 calc(100% - 460px );
  padding: 1rem;
  max-width: calc(100% - 460px);
}
.three-sections .section-two{
  flex: 0 0 230px;
  padding: .5rem 0;
  background: #f0f5f8;
  min-height: 100vh;
  max-width: 230px;
}
.three-sections .section-three{
  flex: 0 0 230px;
  padding: .5rem;
  background: #ffffff;
  min-height: 100vh;
  max-width: 230px;
}


.tast-details{min-height: 100vh;background: #fff;padding: 1rem;}

.tast-details ul li a{
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
.tast-details ul li a:before {
  content: "\e907";
  position: absolute;
  left: 2px;
  font-family: icons;
  font-size: 1rem;
  top: 0px;
  transition: all ease-in-out 0.2s;
  height: 34px;
  width: 34px;
  background: #E5F5EE;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #26806e;
}
.tast-details ul li a span{
  display: block;
  color: #000;
  font-weight: 600;
  margin-bottom: 7px;
}
.tast-details ul li a:hover{
  color: #27816f;
}
.basic-search{
  padding: .5rem;
}
.form-check{
  min-height: .5rem;
}
.form-check-input{
  border: 1px solid #3C4043;
  margin: 0;
}
.form-check-input.sea-blue {
  border-color:#287B9D;
  }
  .form-check-input.sea-blue:checked {
    background-color: #287B9D;
    border-color: #287B9D;
}
.form-check-input.sea-green {
  border-color:#627032;
  }
  .form-check-input.sea-green:checked {
    background-color: #627032;
    border-color: #627032;
}
.form-check-input.sea-black {
  border-color:#000;
  }
  .form-check-input.sea-black:checked {
    background-color: #000;
    border-color: #000;
}

.form-check-input.orange {
  border-color:#F28142;
  }
  .form-check-input.orange:checked {
    background-color: #F28142;
    border-color: #F28142;
}
.form-check-input.dark-blue {
  border-color:#2356BD;
  }
  .form-check-input.dark-blue:checked {
    background-color: #2356BD;
    border-color: #2356BD;
}
.form-check-input.red {
  border-color:#FF0707;
  }
  .form-check-input.red:checked {
    background-color: #FF0707;
    border-color: #FF0707;
}
.form-check-input.pink {
  border-color:#E2176D;
  }
  .form-check-input.pink:checked {
    background-color: #E2176D;
    border-color: #E2176D;
}
.dashboard-top-cards{display: flex;}
.top-card{padding: .7rem .1rem;border: 1px solid #000;margin-right: .3rem;flex: 0 0 calc(100% / 7 - 4px);text-align: center;margin-bottom: .8rem;border-radius: 4px;font-weight: 600;}
.top-card h4{font-weight: 600;font-size: 1.5rem;margin-bottom: .1rem;}
.top-card p{
    margin-bottom: 0;
    font-size: .8rem;
    text-transform: uppercase;
    line-height: .8rem;
}
.top-card:first-child{color: #287C9C;border-color: #287C9C;background: #D7EEF7;}
.top-card:nth-child(2){
  color: #64702F;
  border-color: #64702F;
  background: #F1FCCB;
}
.top-card:nth-child(3){
  color: #F28142;
  border-color: #F28142;
  background: #FFE3D3;
}
.top-card:nth-child(4){
  color: #2355BE;
  border-color: #2355BE;
  background: #D5E2FC;
}
.top-card:nth-child(5){
  color: #B23829;
  border-color: #B23829;
  background: #FFDAD9;
}
.top-card:nth-child(6){
  color: #E2176D;
  border-color: #E2176D;
  background: #FFE5F0;
}

.top-card:last-child{
  margin-right: 0;
  color: #5D3D6D;
  border-color: #5D3D6D;
  background: #F2D9FF;
}

@media screen and (min-width: 1500px) {
  .top-card {
    padding: 1.2rem 0.1rem;}
.top-card h4 {
   
    font-size: 1.8rem;
  }
  .top-card p {
   
    font-size: 1rem;
   
    line-height: .8rem;
}
}
.text-dark-green{
  color: #28876C!important;
}

.text-dark-blue{
  color: #1B4983!important;
}
.mr-1{margin-right: .4rem;}
.mr-2{margin-right: 1rem;}
.mr-5{
  margin-right: 3rem;
}
.nav-pills{
  background: #e1e1e1;
    padding: 0.2rem;
    border-radius: 4px;
}
.nav-pills .nav-link {
    
    padding: 0.2rem .5rem;
    color: #27a9b5;}
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #000;
    background-color: #fff;
}
.pl-0{padding-left: 0;}
.surgeon-list-container{border: 1px solid #E8E8E8;border-radius: 4px;padding: .5rem;margin-bottom: 1rem;}
.surgeon-list{height: 208px;overflow: hidden;overflow-y: auto;padding-right: .5rem;}
.surgeon-item{
    display: flex;
    align-items: center;
    padding: .5rem 0;
    border-bottom: 1px solid #E8E8E8;
}
.surgeon-item:hover{background: transparent linear-gradient(90deg, #0CE3A1 0%, #2F6AFB 100%) 0% 0% no-repeat padding-box;
border-radius: 6px;opacity: 0.25;}
.surgeon-item .img-section{
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 100%;
}
.surgeon-item .img-section img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
}
.surgeon-item .content-section{
    margin-left: .5rem;
}

.patients-details{
display: flex;
justify-content: space-between;
}
.patients-details-left
{
  

   flex: 0 0 320px;
         background: #fff;
         border-radius: 4px;
         min-height: 100vh;
         overflow: hidden;
         padding: 1.5rem;
         padding-left: 3rem;
         position: relative;
}
.patients-details-left .left-heading{
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  background: transparent linear-gradient(93deg, #1A497F 0%, #27836E 100%) 0% 0% no-repeat padding-box;
  transform: rotate(270deg);
  position: absolute;
  left: -772px;
  right: -480px;
  top: 345px;
  height: 36px;
  bottom: 0;
  text-align: center;
  padding: .5rem;
}
.patients-details-right{
  flex: 0 0 calc(100% - 330px);
  margin-left: 10px;
  border-radius: 4px;
}

    
      .profile-details-right{flex: 0 0 calc(100% - 380px)}
      .profile-card{
         display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #e3e3e3;
      }
      .profile-card .img-section{
         height: 80px;
         width: 80px;
         background: transparent linear-gradient(180deg, #05a2fa 0%, #8dfd97 100%) 0% 0% no-repeat padding-box;
         padding: .3rem;
             border-radius: 100%;
             margin-right: .5rem;

      }
    
     .patients-details-left ul li{
         font-weight: 600;
         color: #000;
        
           
          border-bottom: 1px solid #e3e3e3;
              padding: 1rem 0;
    position: relative;
     padding-left: 1rem;
      }
     .patients-details-left ul li:last-child{
         border-bottom: 0px solid #e3e3e3; 
      }
     .patients-details-left ul li:before{
         content: '';
         position: absolute;
         height: 5px;
         width: 5px;
         border-radius: 100%;
         background: #4339F2;
         left: 0;
    top: 21px;
      }
     .patients-details-left ul li span{
        background: #E0E0E0;
    padding: 0.2rem 0rem;
    border-radius: 30px;
    font-size: 1rem;
    position: absolute;
    right: 0;
    width: 35px;
    text-align: center;
 }
     .patients-details-left ul ul{margin-left: 1rem;margin-top: .5rem;}
     .patients-details-left ul ul li{
         border-bottom: 0px solid #e3e3e3;
          padding: .5rem 0;
      }
     .patients-details-left ul ul li:before{
             top: 14px;
    left: -16px;
      }

.patients-details-left ul ul li:nth-child(1):before{
    background: #02A0FC;
}
.patients-details-left ul ul li:nth-child(2):before{
    background: #FFB200;
}
.patients-details-left ul ul li:nth-child(3):before{
    background: #34B53A;
}
.patients-details-left ul ul li:nth-child(4):before{
    background: #DD15D5;
}
.patients-details-left ul ul li:nth-child(5):before{
    background:#FF3A29;
}
.patients-details-left ul ul li:nth-child(6):before{
    background:#FF3A29;
}
.patients-details-left ul ul li:nth-child(7):before{
    background: #4339F2;
}
      .profile-card .img-section img{    border: 4px solid #fff;
    border-radius: 100%;
    height: 100%;
    width: 100%;}
      .profile-card .details-section{}
      .profile-card .details-section h6{font-weight: 600;margin-bottom: .4rem;}
      .profile-card .details-section p{    font-weight: 600;
    margin-bottom: .1rem;
    font-size: 1rem;}


    
.calendar, .calendar_weekdays, .calendar_content {
  max-width: 450px; }

.calendar {
  margin: auto;
  font-weight: 400; }

.calendar_content {
  background: #fff; }

.calendar_weekdays {
  background: #1089ff; }

.calendar_header {
  padding: 10px 0; }

.calendar_content, .calendar_weekdays, .calendar_header {
  position: relative;
  overflow: hidden; }

.calendar_weekdays div {
  display: inline-block;
  vertical-align: top;
  color: #fff !important; }

.calendar_weekdays div, .calendar_content div {
  width: 14.28571%;
  overflow: hidden;
  text-align: center;
  background-color: transparent;
  color: #000;
  font-size: 18px;
  padding: 15px 0; }

.calendar_content div {
  border: 1px solid transparent;
  float: left;
  position: relative;
  z-index: 0; }

.calendar_content div:hover {
  border: 1px solid #dcdcdc;
  cursor: default; }

.calendar_content div.blank:hover {
  cursor: default;
  border: 1px solid transparent; }

.calendar_content div.past-date {
  color: #d5d5d5; }

.calendar_content div.today {
  font-weight: bold;
  font-size: 18px;
  color: #fff !important; }
  .calendar_content div.today:after {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    background: #1089ff;
    z-index: -1;
    -webkit-box-shadow: 0px 5px 11px -9px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 0px 5px 11px -9px rgba(0, 0, 0, 0.48);
    box-shadow: 0px 5px 11px -9px rgba(0, 0, 0, 0.48); }

.calendar_content div.selected {
  background-color: #f0f0f0; }

.calendar_header {
  width: 100%;
  text-align: center; }

.calendar_header h2 {
  padding: 0 10px;
  font-weight: 500;
  font-size: 24px;
  color: #1089ff;
  float: left;
  width: 70%;
  margin: 0 0 10px; }

button.switch-month {
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  color: #dcdcdc;
  float: left;
  width: 15%;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s; }

button.switch-month:hover {
  color: #1089ff; }
.text-warning{
color: #FF5800!important;
}
.text-primary{
color:#2F69FA!important;
}
.pl-0{
  padding-left: 0!important;
}
.preference-card-items{
  padding: 0rem 1.5rem;
}
.preference-item{
      background: #fff;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 4px;
       min-height: 255px;
       transition: all ease-in-out .5s;

}
.preference-item:hover{
  box-shadow: 0 3px 6px #cbcbcb;
}
.preference-item img{height: 95px;margin: 0 auto 1rem;width: auto;}
.preference-item h4{color: #298C6D;font-size: 1.2rem;font-weight: 600;margin-bottom: .4rem;}
.preference-item p{color: #404040;font-weight: 600;margin-bottom: .5rem;}
.preference-item p span{margin-right: .5rem;}
.preference-item .btn{padding: 0.2rem 1rem;}

.custom-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* opacity: 0; */
    /* visibility: hidden; */
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
        z-index: 999;
}

.custom-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    
    width: 430px;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 20px #0000004E;
    border-radius: 4px;
    overflow: hidden;
}

.custom-modal-header{
  display: flex;
  background: transparent linear-gradient(180deg, #F0F3F5 0%, #E9F1F7 100%) 0% 0% no-repeat;
  justify-content: space-between;
  align-items: center;
  padding: .4rem 1.2rem;

}
.close-button {
    
    
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
   font-size: 1.7rem;
}

.close-button:hover {
    color: #000;
}

.custom-modal-body{
   padding: 1.2rem;
}

.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
.ml-1{
  margin-left: .5rem;
}

.tab-section{
  display: flex;
  justify-content: center;
  background: #249a82;
    background: transparent linear-gradient(
93deg
, #1A497F 0%, #27836E 100%) 0% 0% no-repeat padding-box;
    padding: .5rem 1rem;

}
.tab-section .nav-pills{
  background: transparent;
  padding: 0;
}
.tab-section .nav-pills .nav-link{
color: #fff;
text-transform: uppercase;
border-radius: 4px;
padding:.4rem 1.5rem;
transition: all ease-in-out .4s;
font-family: Inter;

}
.tab-section .nav-pills .nav-link:hover,.tab-section .nav-pills .nav-link.active{
color: #35F5FF;
background: rgba(0,0,0,.5);

}
.tab-section2{
  display: flex;
  justify-content: center;
  background: #fff;
  
    padding: .5rem 1rem;

}
.tab-section2 .nav-pills{
  background: transparent;
  padding: 0;
}
.tab-section2 .nav-pills .nav-link{
color: #000000;
text-transform: uppercase;
border-radius: 25px;
padding:.4rem 1.5rem;
transition: all ease-in-out .4s;
font-family: Inter;

}
.tab-section2 .nav-pills .nav-link:hover,.tab-section2 .nav-pills .nav-link.active{
color: #fff;
background: #298C6D;

}
  .pt-20{
      padding-top: 20px;
    }
    .text-right{
      text-align: right;
    }
    .debit-card{padding: 1.2rem;color: #fff;margin-bottom: 1rem;}
    .debit-card .card-heading{display: flex;justify-content: space-between;align-items: center;}
      .debit-card .card-heading span{
      
    }
       .debit-card .card-heading i{
      font-size: 2.3rem;
    }
    .debit-card .card-number{
    display: flex;
    justify-content: space-between;
    margin: 1.3rem 0;
}
    .debit-card .card-number span{
    letter-spacing: 5px;
    text-shadow: 1px 1px 3px #000;
}
    .debit-card .other-details{
    display: flex;
    justify-content: space-between;
    
}
    .debit-card .other-details small{
    display: block;
    margin-bottom: .4rem;
    
}
.card-red{
  background: #e94b4b!important;
}

.card-gadent-purple{
  background: #8a5094!important;
  background-image: linear-gradient(to right, #8a5094 , #6a3074)!important;
}

.card-brown{
  background: #6a5651!important;
   background-image: linear-gradient(to right, #6a5651 , #53413c)!important;
}

.card-purple{
  background: #6141da!important;
}
.pr-4{
  padding-right: 2rem;
}
.bg-sea-blue{
  background: #298C6D;
}
.bg-sea-green{
  background: #1CD1B8;
}
.bg-blue{
  background: #1B4B82;
}
.bg-dr-blue{
  background: #18355C;
}
.bg-dr-gray{
  background: #404040;
}

.bg-orange{
  background: #FF7307;
}
.bg-lt-red{
  background: #D94B4B;
}
.bg-lt-gray{
  background: #878787;
}


@media screen and (max-width: 1440px) {
  .debit-card{padding: .8rem;}
   .debit-card .card-heading span{
      font-size: .9rem;
    }
       .debit-card .card-heading i{
      font-size:1.8rem;
    }
 .debit-card .card-number{
   
    margin: 1rem 0;
}
.debit-card .card-number span{
    letter-spacing: 2px;
 
}
.debit-card .other-details{
  font-size: .9rem;
    
}
}





@media screen and (max-width: 1160px) {
.two-sections{
  display: flex;
   flex-wrap: wrap;
  justify-content: space-between;
}
.two-sections .section-one{
  flex: 0 0 calc(100% - 230px );
  padding:.4rem .5rem;
}
.two-sections .section-two{
  flex: 0 0 230px;
  padding: .5rem 0;
  background: #f0f5f8;
}
.three-sections{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.three-sections .section-one{
  flex: 0 0 calc(100% - 460px );
  padding: 1rem;
  max-width: calc(100% - 460px);
}
.three-sections .section-two{
  flex: 0 0 230px;
  padding: .5rem 0;
  background: #f0f5f8;
  min-height: 100vh;
  max-width: 230px;
}
.three-sections .section-three{
  flex: 0 0 230px;
  padding: .5rem;
  background: #ffffff;
  min-height: 100vh;
  max-width: 230px;
}


}

.welcome-page-header{
  color: #232424;
   font-family: 'Inter';
   font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
.welcome-page-header1{
  color: #747576;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.welcome-page{
  text-align: "justify";
    padding: "10px";
    font-weight:"500";
    font-family: 'Inter';
    font-size:"14px";
    color:"gray";
    line-height:"19.6px"
}

.input{
  outline: none;
 border-top: none;
 border-left: none;
 border-right: none;
  border-bottom: #f9f2f2 2px solid;
  padding-bottom: 12px;
  padding-top: 10px;
  margin-top: 4px;
  border-radius: 10px; 
}



