@font-face { 
  font-family: 'icons';
  src:  url('../fonts/icons.eot?voa8nu');
  src:  url('../fonts/icons.eot?voa8nu#iefix') format('embedded-opentype'),
    url('../fonts/icons.ttf?voa8nu') format('truetype'),
    url('../fonts/icons.woff?voa8nu') format('woff'),
    url('../fonts/icons.svg?voa8nu#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chat .path1:before {
  content: "\f055";
  color: rgb(26, 24, 24);
}
.icon-chat .path2:before {
  content: "\f056";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-chat .path3:before {
  content: "\f057";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-chat .path4:before {
  content: "\f058";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-barchart .path1:before {
  content: "\f045";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-barchart .path2:before {
  content: "\f059";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barchart .path3:before {
  content: "\f05a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-barchart .path4:before {
  content: "\f05b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-blocks .path1:before {
  content: "\f046";
  color: rgb(0, 0, 0);
}
.icon-blocks .path2:before {
  content: "\f05c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-builder .path1:before {
  content: "\f047";
  color: rgb(0, 0, 0);
}
.icon-builder .path2:before {
  content: "\f05d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-cards .path1:before {
  content: "\f048";
  color: rgb(0, 0, 0);
}
.icon-cards .path2:before {
  content: "\f05e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-compiling .path1:before {
  content: "\f049";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-compiling .path2:before {
  content: "\f05f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Dashboard1 .path1:before {
  content: "\f04a";
  color: rgb(0, 0, 0);
}
.icon-Dashboard1 .path2:before {
  content: "\f060";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-heartbeat .path1:before {
  content: "\f04b";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-heartbeat .path2:before {
  content: "\f061";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-heartbeat .path3:before {
  content: "\f062";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-leftalign .path1:before {
  content: "\f04c";
  color: rgb(0, 0, 0);
}
.icon-leftalign .path2:before {
  content: "\f063";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-mail:before {
  content: "\f04d";
}
.icon-mansheled:before {
  content: "\f04e";
}
.icon-pages1 .path1:before {
  content: "\f04f";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-pages1 .path2:before {
  content: "\f064";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-penrular .path1:before {
  content: "\f050";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-penrular .path2:before {
  content: "\f065";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-reload:before {
  content: "\f051";
}
.icon-setting4 .path1:before {
  content: "\f052";
  color: rgb(0, 0, 0);
}
.icon-setting4 .path2:before {
  content: "\f066";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-themes .path1:before {
  content: "\f053";
  color: rgb(0, 0, 0);
}
.icon-themes .path2:before {
  content: "\f067";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-Dashboard .path1:before {
  content: "\f044";
  color: rgb(0, 0, 0);
}
.icon-Dashboard .path2:before {
  content: "\f068";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-antenna:before {
  content: "\f054";
}
.icon-arrow1:before {
  content: "\ef32";
}
.icon-arrow2:before {
  content: "\ef33";
}
.icon-arrow3:before {
  content: "\ef34";
}
.icon-arrow4:before {
  content: "\ef35";
}
.icon-arrow5:before {
  content: "\ef36";
}
.icon-arrow6:before {
  content: "\ef37";
}
.icon-arrow7:before {
  content: "\ef38";
}
.icon-arrow8:before {
  content: "\ef39";
}
.icon-arrow9:before {
  content: "\ef3a";
}
.icon-arrow10:before {
  content: "\ef3b";
}
.icon-arrow11:before {
  content: "\ef3c";
}
.icon-arrow12:before {
  content: "\ef3d";
}
.icon-arrow13:before {
  content: "\ef3e";
}
.icon-arrow14:before {
  content: "\ef3f";
}
.icon-arrow15:before {
  content: "\ef40";
}
.icon-arrow16:before {
  content: "\ef41";
}
.icon-arrow17:before {
  content: "\ef42";
}
.icon-arrow18:before {
  content: "\ef43";
}
.icon-arrow19:before {
  content: "\ef44";
}
.icon-arrow20:before {
  content: "\ef45";
}
.icon-arrow21:before {
  content: "\ef46";
}
.icon-arrow22:before {
  content: "\ef47";
}
.icon-arrow23:before {
  content: "\ef48";
}
.icon-arrow24:before {
  content: "\ef49";
}
.icon-arrow25:before {
  content: "\ef4a";
}
.icon-arrow26:before {
  content: "\ef4b";
}
.icon-arrow27:before {
  content: "\ef4c";
}
.icon-arrow28:before {
  content: "\ef4d";
}
.icon-arrow29:before {
  content: "\ef4e";
}
.icon-arrow30:before {
  content: "\ef4f";
}
.icon-arrow31:before {
  content: "\ef50";
}
.icon-arrow32:before {
  content: "\ef51";
}
.icon-arrow33:before {
  content: "\ef52";
}
.icon-arrow34:before {
  content: "\ef53";
}
.icon-arrow35:before {
  content: "\ef54";
}
.icon-arrow36:before {
  content: "\ef55";
}
.icon-arrow37:before {
  content: "\ef56";
}
.icon-arrow38:before {
  content: "\ef57";
}
.icon-arrow39:before {
  content: "\ef58";
}
.icon-arrow40:before {
  content: "\ef59";
}
.icon-arrow41:before {
  content: "\ef5a";
}
.icon-arrow42:before {
  content: "\ef5b";
}
.icon-arrow43:before {
  content: "\ef5c";
}
.icon-arrow44:before {
  content: "\ef5d";
}
.icon-arrow45:before {
  content: "\ef5e";
}
.icon-arrow46:before {
  content: "\ef5f";
}
.icon-arrow47:before {
  content: "\ef60";
}
.icon-arrow48:before {
  content: "\ef61";
}
.icon-arrow49:before {
  content: "\ef62";
}
.icon-arrow50:before {
  content: "\ef63";
}
.icon-arrow51:before {
  content: "\ef64";
}
.icon-arrow52:before {
  content: "\ef65";
}
.icon-arrow53:before {
  content: "\ef66";
}
.icon-arrow54:before {
  content: "\ef67";
}
.icon-arrow55:before {
  content: "\ef68";
}
.icon-arrow56:before {
  content: "\ef69";
}
.icon-arrow57:before {
  content: "\ef6a";
}
.icon-arrow58:before {
  content: "\ef6b";
}
.icon-arrow59:before {
  content: "\ef6c";
}
.icon-arrow60:before {
  content: "\ef6d";
}
.icon-arrow61:before {
  content: "\ef6e";
}
.icon-arrow62:before {
  content: "\ef6f";
}
.icon-arrow63:before {
  content: "\ef70";
}
.icon-badge:before {
  content: "\ef71";
}
.icon-bag:before {
  content: "\ef72";
}
.icon-bag2:before {
  content: "\ef73";
}
.icon-bakelitedisk:before {
  content: "\ef74";
}
.icon-bankcard:before {
  content: "\ef75";
}
.icon-battery1:before {
  content: "\ef76";
}
.icon-battery2:before {
  content: "\ef77";
}
.icon-battery3:before {
  content: "\ef78";
}
.icon-battery4:before {
  content: "\ef79";
}
.icon-battery5:before {
  content: "\ef7a";
}
.icon-battery6:before {
  content: "\ef7b";
}
.icon-battery7:before {
  content: "\ef7c";
}
.icon-behance1:before {
  content: "\ef7d";
}
.icon-behance2:before {
  content: "\ef7e";
}
.icon-bell1:before {
  content: "\ef7f";
}
.icon-birdpen:before {
  content: "\ef80";
}
.icon-blogger1:before {
  content: "\ef81";
}
.icon-blogger2:before {
  content: "\ef82";
}
.icon-brush:before {
  content: "\ef83";
}
.icon-calculator:before {
  content: "\ef84";
}
.icon-calendar1:before {
  content: "\ef85";
}
.icon-camera:before {
  content: "\ef86";
}
.icon-camera2:before {
  content: "\ef87";
}
.icon-camera3:before {
  content: "\ef88";
}
.icon-camera4:before {
  content: "\ef89";
}
.icon-cart1:before {
  content: "\ef8a";
}
.icon-cart2:before {
  content: "\ef8b";
}
.icon-cart3:before {
  content: "\ef8c";
}
.icon-cart31:before {
  content: "\ef8d";
}
.icon-cart32:before {
  content: "\ef8e";
}
.icon-chat1:before {
  content: "\ef8f";
}
.icon-chat2:before {
  content: "\ef90";
}
.icon-classictelephone:before {
  content: "\ef91";
}
.icon-clock1:before {
  content: "\ef92";
}
.icon-cordlessscrewdriver:before {
  content: "\ef93";
}
.icon-cpu:before {
  content: "\ef94";
}
.icon-deviantart1:before {
  content: "\ef95";
}
.icon-deviantart2:before {
  content: "\ef96";
}
.icon-diagram:before {
  content: "\ef97";
}
.icon-diagram2:before {
  content: "\ef98";
}
.icon-digg1:before {
  content: "\ef99";
}
.icon-digg2:before {
  content: "\ef9a";
}
.icon-download1:before {
  content: "\ef9b";
}
.icon-download2:before {
  content: "\ef9c";
}
.icon-drbag:before {
  content: "\ef9d";
}
.icon-dribbble1:before {
  content: "\ef9e";
}
.icon-dribbble2:before {
  content: "\ef9f";
}
.icon-email:before {
  content: "\efa0";
}
.icon-email2:before {
  content: "\efa1";
}
.icon-emoticon1:before {
  content: "\efa2";
}
.icon-emoticon2:before {
  content: "\efa3";
}
.icon-emoticon3:before {
  content: "\efa4";
}
.icon-emoticon4:before {
  content: "\efa5";
}
.icon-emoticon5:before {
  content: "\efa6";
}
.icon-emoticon6:before {
  content: "\efa7";
}
.icon-emoticon7:before {
  content: "\efa8";
}
.icon-emoticon8:before {
  content: "\efa9";
}
.icon-emoticon9:before {
  content: "\efaa";
}
.icon-emoticon10:before {
  content: "\efab";
}
.icon-emoticon11:before {
  content: "\efac";
}
.icon-emoticon12:before {
  content: "\efad";
}
.icon-emoticon13:before {
  content: "\efae";
}
.icon-emoticon14:before {
  content: "\efaf";
}
.icon-emoticon15:before {
  content: "\efb0";
}
.icon-emoticon16:before {
  content: "\efb1";
}
.icon-emoticon17:before {
  content: "\efb2";
}
.icon-emoticon18:before {
  content: "\efb3";
}
.icon-emoticon19:before {
  content: "\efb4";
}
.icon-emoticon20:before {
  content: "\efb5";
}
.icon-emoticon21:before {
  content: "\efb6";
}
.icon-emoticon22:before {
  content: "\efb7";
}
.icon-emoticon23:before {
  content: "\efb8";
}
.icon-emoticon24:before {
  content: "\efb9";
}
.icon-emoticon25:before {
  content: "\efba";
}
.icon-emoticon26:before {
  content: "\efbb";
}
.icon-emoticon27:before {
  content: "\efbc";
}
.icon-evernote1:before {
  content: "\efbd";
}
.icon-evernote2:before {
  content: "\efbe";
}
.icon-eye11:before {
  content: "\efbf";
}
.icon-facebook1:before {
  content: "\efc0";
}
.icon-facebook2:before {
  content: "\efc1";
}
.icon-feedback:before {
  content: "\efc2";
}
.icon-feedback2:before {
  content: "\efc3";
}
.icon-feedback3:before {
  content: "\efc4";
}
.icon-flickr1:before {
  content: "\efc5";
}
.icon-flickr2:before {
  content: "\efc6";
}
.icon-floppydisk:before {
  content: "\efc7";
}
.icon-gamepad:before {
  content: "\efc8";
}
.icon-gift:before {
  content: "\efc9";
}
.icon-gold1:before {
  content: "\efca";
}
.icon-gold2:before {
  content: "\efcb";
}
.icon-gold3:before {
  content: "\efcc";
}
.icon-gold4:before {
  content: "\efcd";
}
.icon-gold5:before {
  content: "\efce";
}
.icon-gplus:before {
  content: "\efcf";
}
.icon-gplus2:before {
  content: "\efd0";
}
.icon-hammer:before {
  content: "\efd1";
}
.icon-headphone:before {
  content: "\efd2";
}
.icon-heart1:before {
  content: "\efd3";
}
.icon-home:before {
  content: "\efd4";
}
.icon-house:before {
  content: "\efd5";
}
.icon-imac:before {
  content: "\efd6";
}
.icon-ipod:before {
  content: "\efd7";
}
.icon-judicialgavel:before {
  content: "\efd8";
}
.icon-keyboard1:before {
  content: "\efd9";
}
.icon-lamp:before {
  content: "\efda";
}
.icon-laptop:before {
  content: "\efdb";
}
.icon-lecturer:before {
  content: "\efdc";
}
.icon-linkedin1:before {
  content: "\efdd";
}
.icon-linkedin2:before {
  content: "\efde";
}
.icon-map1:before {
  content: "\efdf";
}
.icon-map2:before {
  content: "\efe0";
}
.icon-map3:before {
  content: "\efe1";
}
.icon-mic1:before {
  content: "\efe2";
}
.icon-mic2:before {
  content: "\efe3";
}
.icon-money1:before {
  content: "\efe4";
}
.icon-money2:before {
  content: "\efe5";
}
.icon-money3:before {
  content: "\efe6";
}
.icon-money4:before {
  content: "\efe7";
}
.icon-moneybag:before {
  content: "\efe8";
}
.icon-moneypig:before {
  content: "\efe9";
}
.icon-monitor:before {
  content: "\efea";
}
.icon-mouse:before {
  content: "\efeb";
}
.icon-nocloud:before {
  content: "\efec";
}
.icon-padlock1:before {
  content: "\efed";
}
.icon-padlock2:before {
  content: "\efee";
}
.icon-paintcylinder:before {
  content: "\efef";
}
.icon-pen:before {
  content: "\eff0";
}
.icon-pencil:before {
  content: "\eff1";
}
.icon-pinterest1:before {
  content: "\eff2";
}
.icon-pinterest2:before {
  content: "\eff3";
}
.icon-pipette:before {
  content: "\eff4";
}
.icon-polaroid:before {
  content: "\eff5";
}
.icon-presentation:before {
  content: "\eff6";
}
.icon-printer:before {
  content: "\eff7";
}
.icon-projector:before {
  content: "\eff8";
}
.icon-push:before {
  content: "\eff9";
}
.icon-radio:before {
  content: "\effa";
}
.icon-register:before {
  content: "\effb";
}
.icon-registration:before {
  content: "\effc";
}
.icon-safe:before {
  content: "\effd";
}
.icon-scissors:before {
  content: "\effe";
}
.icon-screwdriver:before {
  content: "\efff";
}
.icon-search1:before {
  content: "\f000";
}
.icon-search2:before {
  content: "\f001";
}
.icon-search3:before {
  content: "\f002";
}
.icon-setting1:before {
  content: "\f003";
}
.icon-setting2:before {
  content: "\f004";
}
.icon-setting3:before {
  content: "\f005";
}
.icon-shop:before {
  content: "\f006";
}
.icon-skype1:before {
  content: "\f007";
}
.icon-skype2:before {
  content: "\f008";
}
.icon-soundcloud1:before {
  content: "\f009";
}
.icon-soundcloud2:before {
  content: "\f00a";
}
.icon-speaker1:before {
  content: "\f00b";
}
.icon-speaker2:before {
  content: "\f00c";
}
.icon-speaker3:before {
  content: "\f00d";
}
.icon-speaker4:before {
  content: "\f00e";
}
.icon-star1:before {
  content: "\f00f";
}
.icon-star2:before {
  content: "\f010";
}
.icon-star3:before {
  content: "\f011";
}
.icon-telephone:before {
  content: "\f012";
}
.icon-theme:before {
  content: "\f013";
}
.icon-trash:before {
  content: "\f014";
}
.icon-tv:before {
  content: "\f015";
}
.icon-twitter1:before {
  content: "\f016";
}
.icon-twitter2:before {
  content: "\f017";
}
.icon-twitter3:before {
  content: "\f018";
}
.icon-upload1:before {
  content: "\f019";
}
.icon-upload2:before {
  content: "\f01a";
}
.icon-usb1:before {
  content: "\f01b";
}
.icon-usb2:before {
  content: "\f01d";
}
.icon-user1:before {
  content: "\f01e";
}
.icon-user2:before {
  content: "\f01f";
}
.icon-user3:before {
  content: "\f020";
}
.icon-users:before {
  content: "\f021";
}
.icon-vimeo1:before {
  content: "\f022";
}
.icon-vimeo2:before {
  content: "\f023";
}
.icon-vlc:before {
  content: "\f024";
}
.icon-vote:before {
  content: "\f025";
}
.icon-weather1:before {
  content: "\f026";
}
.icon-weather2:before {
  content: "\f027";
}
.icon-weather3:before {
  content: "\f028";
}
.icon-weather4:before {
  content: "\f029";
}
.icon-weather5:before {
  content: "\f02a";
}
.icon-weather6:before {
  content: "\f02b";
}
.icon-weather7:before {
  content: "\f02c";
}
.icon-weather8:before {
  content: "\f02d";
}
.icon-weather9:before {
  content: "\f02e";
}
.icon-weather10:before {
  content: "\f02f";
}
.icon-weather11:before {
  content: "\f030";
}
.icon-weather12:before {
  content: "\f031";
}
.icon-weather13:before {
  content: "\f032";
}
.icon-weather14:before {
  content: "\f033";
}
.icon-weather15:before {
  content: "\f034";
}
.icon-weather16:before {
  content: "\f035";
}
.icon-weather17:before {
  content: "\f036";
}
.icon-weather18:before {
  content: "\f037";
}
.icon-weather19:before {
  content: "\f038";
}
.icon-weather20:before {
  content: "\f039";
}
.icon-weather21:before {
  content: "\f03a";
}
.icon-weather22:before {
  content: "\f03b";
}
.icon-weather23:before {
  content: "\f03c";
}
.icon-weather24:before {
  content: "\f03d";
}
.icon-weather25:before {
  content: "\f03e";
}
.icon-weather26:before {
  content: "\f03f";
}
.icon-webcam:before {
  content: "\f040";
}
.icon-wrench:before {
  content: "\f041";
}
.icon-wrench2:before {
  content: "\f042";
}
.icon-youtube1:before {
  content: "\f043";
}
.icon-px:before {
  content: "\e900";
}
.icon-accessible-icon:before {
  content: "\e901";
}
.icon-accusoft:before {
  content: "\e902";
}
.icon-acquisitions-incorporated:before {
  content: "\e903";
}
.icon-address-book:before {
  content: "\e904";
}
.icon-address-book-solid:before {
  content: "\e905";
}
.icon-address-card:before {
  content: "\e906";
}
.icon-address-card-solid:before {
  content: "\e908";
}
.icon-adjust-solid:before {
  content: "\e90a";
}
.icon-adn:before {
  content: "\e90c";
}
.icon-adobe:before {
  content: "\e90e";
}
.icon-ad-solid:before {
  content: "\e910";
}
.icon-adversal:before {
  content: "\e912";
}
.icon-affiliatetheme:before {
  content: "\e914";
}
.icon-airbnb:before {
  content: "\e916";
}
.icon-air-freshener-solid:before {
  content: "\e918";
}
.icon-algolia:before {
  content: "\e91a";
}
.icon-align-center-solid:before {
  content: "\e91c";
}
.icon-align-justify-solid:before {
  content: "\e91e";
}
.icon-align-left-solid:before {
  content: "\e91f";
}
.icon-align-right-solid:before {
  content: "\e920";
}
.icon-alipay:before {
  content: "\e924";
}
.icon-allergies-solid:before {
  content: "\e926";
}
.icon-amazon:before {
  content: "\e928";
}
.icon-amazon-pay:before {
  content: "\e92a";
}
.icon-ambulance-solid:before {
  content: "\e92c";
}
.icon-american-sign-language-interpreting-solid:before {
  content: "\e92e";
}
.icon-amilia:before {
  content: "\e930";
}
.icon-anchor-solid:before {
  content: "\e932";
}
.icon-android:before {
  content: "\e934";
}
.icon-angellist:before {
  content: "\e936";
}
.icon-angle-double-down-solid:before {
  content: "\e938";
}
.icon-angle-double-left-solid:before {
  content: "\e93a";
}
.icon-angle-double-right-solid:before {
  content: "\e93c";
}
.icon-angle-double-up-solid:before {
  content: "\e93e";
}
.icon-angle-down-solid:before {
  content: "\e940";
}
.icon-angle-left-solid:before {
  content: "\e942";
}
.icon-angle-right-solid:before {
  content: "\e944";
}
.icon-angle-up-solid:before {
  content: "\e946";
}
.icon-angry:before {
  content: "\e948";
}
.icon-angrycreative:before {
  content: "\e94a";
}
.icon-angry-solid:before {
  content: "\e94c";
}
.icon-angular:before {
  content: "\e94e";
}
.icon-ankh-solid:before {
  content: "\e950";
}
.icon-apper:before {
  content: "\e952";
}
.icon-apple:before {
  content: "\e954";
}
.icon-apple-alt-solid:before {
  content: "\e955";
}
.icon-apple-pay:before {
  content: "\e956";
}
.icon-app-store:before {
  content: "\e957";
}
.icon-app-store-ios:before {
  content: "\e958";
}
.icon-archive-solid:before {
  content: "\e959";
}
.icon-archway-solid:before {
  content: "\e95a";
}
.icon-arrow-alt-circle-down:before {
  content: "\e95b";
}
.icon-arrow-alt-circle-down-solid:before {
  content: "\e95c";
}
.icon-arrow-alt-circle-left:before {
  content: "\e95d";
}
.icon-arrow-alt-circle-left-solid:before {
  content: "\e95e";
}
.icon-arrow-alt-circle-right:before {
  content: "\e95f";
}
.icon-arrow-alt-circle-right-solid:before {
  content: "\e960";
}
.icon-arrow-alt-circle-up:before {
  content: "\e961";
}
.icon-arrow-alt-circle-up-solid:before {
  content: "\e962";
}
.icon-arrow-circle-down-solid:before {
  content: "\e963";
}
.icon-arrow-circle-left-solid:before {
  content: "\e964";
}
.icon-arrow-circle-right-solid:before {
  content: "\e965";
}
.icon-arrow-circle-up-solid:before {
  content: "\e966";
}
.icon-arrow-down-solid:before {
  content: "\e967";
}
.icon-arrow-left-solid:before {
  content: "\e968";
}
.icon-arrow-right-solid:before {
  content: "\e969";
}
.icon-arrows-alt-h-solid:before {
  content: "\e96a";
}
.icon-arrows-alt-solid:before {
  content: "\e96b";
}
.icon-arrows-alt-v-solid:before {
  content: "\e96c";
}
.icon-arrow-up-solid:before {
  content: "\e96d";
}
.icon-artstation:before {
  content: "\e96e";
}
.icon-assistive-listening-systems-solid:before {
  content: "\e96f";
}
.icon-asterisk-solid:before {
  content: "\e970";
}
.icon-asymmetrik:before {
  content: "\e971";
}
.icon-atlassian:before {
  content: "\e972";
}
.icon-atlas-solid:before {
  content: "\e973";
}
.icon-atom-solid:before {
  content: "\e974";
}
.icon-at-solid:before {
  content: "\e975";
}
.icon-audible:before {
  content: "\e976";
}
.icon-audio-description-solid:before {
  content: "\e977";
}
.icon-autoprefixer:before {
  content: "\e978";
}
.icon-avianex:before {
  content: "\e979";
}
.icon-aviato:before {
  content: "\e97a";
}
.icon-award-solid:before {
  content: "\e97b";
}
.icon-aws:before {
  content: "\e97c";
}
.icon-baby-carriage-solid:before {
  content: "\e97d";
}
.icon-baby-solid:before {
  content: "\e97e";
}
.icon-backspace-solid:before {
  content: "\e97f";
}
.icon-backward-solid:before {
  content: "\e980";
}
.icon-bacon-solid:before {
  content: "\e981";
}
.icon-balance-scale-left-solid:before {
  content: "\e982";
}
.icon-balance-scale-right-solid:before {
  content: "\e983";
}
.icon-balance-scale-solid:before {
  content: "\e984";
}
.icon-band-aid-solid:before {
  content: "\e985";
}
.icon-bandcamp:before {
  content: "\e986";
}
.icon-ban-solid:before {
  content: "\e987";
}
.icon-barcode-solid:before {
  content: "\e988";
}
.icon-bars-solid:before {
  content: "\e989";
}
.icon-baseball-ball-solid:before {
  content: "\e98a";
}
.icon-basketball-ball-solid:before {
  content: "\e98b";
}
.icon-bath-solid:before {
  content: "\e98c";
}
.icon-battery-empty-solid:before {
  content: "\e98d";
}
.icon-battery-full-solid:before {
  content: "\e98e";
}
.icon-battery-half-solid:before {
  content: "\e98f";
}
.icon-battery-quarter-solid:before {
  content: "\e990";
}
.icon-battery-three-quarters-solid:before {
  content: "\e991";
}
.icon-battle-net:before {
  content: "\e992";
}
.icon-bed-solid:before {
  content: "\e993";
}
.icon-beer-solid:before {
  content: "\e994";
}
.icon-behance:before {
  content: "\e995";
}
.icon-behance-square:before {
  content: "\e996";
}
.icon-bell:before {
  content: "\e997";
}
.icon-bell-slash:before {
  content: "\e998";
}
.icon-bell-slash-solid:before {
  content: "\e999";
}
.icon-bell-solid:before {
  content: "\e99a";
}
.icon-bezier-curve-solid:before {
  content: "\e99b";
}
.icon-bible-solid:before {
  content: "\e99c";
}
.icon-bicycle-solid:before {
  content: "\e99d";
}
.icon-biking-solid:before {
  content: "\e99e";
}
.icon-bimobject:before {
  content: "\e99f";
}
.icon-binoculars-solid:before {
  content: "\e9a0";
}
.icon-biohazard-solid:before {
  content: "\e9a1";
}
.icon-birthday-cake-solid:before {
  content: "\e9a2";
}
.icon-bitbucket:before {
  content: "\e9a3";
}
.icon-bitcoin:before {
  content: "\e9a4";
}
.icon-bity:before {
  content: "\e9a5";
}
.icon-blackberry:before {
  content: "\e9a6";
}
.icon-black-tie:before {
  content: "\e9a7";
}
.icon-blender-phone-solid:before {
  content: "\e9a8";
}
.icon-blender-solid:before {
  content: "\e9a9";
}
.icon-blind-solid:before {
  content: "\e9aa";
}
.icon-blogger:before {
  content: "\e9ab";
}
.icon-blogger-b:before {
  content: "\e9ac";
}
.icon-blog-solid:before {
  content: "\e9ad";
}
.icon-bluetooth:before {
  content: "\e9ae";
}
.icon-bluetooth-b:before {
  content: "\e9af";
}
.icon-bold-solid:before {
  content: "\e9b0";
}
.icon-bolt-solid:before {
  content: "\e9b1";
}
.icon-bomb-solid:before {
  content: "\e9b2";
}
.icon-bone-solid:before {
  content: "\e9b3";
}
.icon-bong-solid:before {
  content: "\e9b4";
}
.icon-book-dead-solid:before {
  content: "\e9b5";
}
.icon-bookmark:before {
  content: "\e9b6";
}
.icon-bookmark-solid:before {
  content: "\e9b7";
}
.icon-book-medical-solid:before {
  content: "\e9b8";
}
.icon-book-open-solid:before {
  content: "\e9b9";
}
.icon-book-reader-solid:before {
  content: "\e9ba";
}
.icon-book-solid:before {
  content: "\e9bb";
}
.icon-bootstrap:before {
  content: "\e9bc";
}
.icon-border-all-solid:before {
  content: "\e9bd";
}
.icon-border-none-solid:before {
  content: "\e9be";
}
.icon-border-style-solid:before {
  content: "\e9bf";
}
.icon-bowling-ball-solid:before {
  content: "\e9c0";
}
.icon-boxes-solid:before {
  content: "\e9c1";
}
.icon-box-open-solid:before {
  content: "\e9c2";
}
.icon-box-solid:before {
  content: "\e9c3";
}
.icon-braille-solid:before {
  content: "\e9c4";
}
.icon-brain-solid:before {
  content: "\e9c5";
}
.icon-bread-slice-solid:before {
  content: "\e9c6";
}
.icon-briefcase-medical-solid:before {
  content: "\e9c7";
}
.icon-briefcase-solid:before {
  content: "\e9c8";
}
.icon-broadcast-tower-solid:before {
  content: "\e9c9";
}
.icon-broom-solid:before {
  content: "\e9ca";
}
.icon-brush-solid:before {
  content: "\e9cb";
}
.icon-btc:before {
  content: "\e9cc";
}
.icon-buffer:before {
  content: "\e9cd";
}
.icon-bug-solid:before {
  content: "\e9ce";
}
.icon-building:before {
  content: "\e9cf";
}
.icon-building-solid:before {
  content: "\e9d0";
}
.icon-bullhorn-solid:before {
  content: "\e9d1";
}
.icon-bullseye-solid:before {
  content: "\e9d2";
}
.icon-burn-solid:before {
  content: "\e9d3";
}
.icon-buromobelexperte:before {
  content: "\e9d4";
}
.icon-bus-alt-solid:before {
  content: "\e9d5";
}
.icon-business-time-solid:before {
  content: "\e9d6";
}
.icon-bus-solid:before {
  content: "\e9d7";
}
.icon-buy-n-large:before {
  content: "\e9d8";
}
.icon-buysellads:before {
  content: "\e9d9";
}
.icon-calculator-solid:before {
  content: "\e9da";
}
.icon-calendar:before {
  content: "\e9db";
}
.icon-calendar-alt:before {
  content: "\e9dc";
}
.icon-calendar-alt-solid:before {
  content: "\e9dd";
}
.icon-calendar-check:before {
  content: "\e9de";
}
.icon-calendar-check-solid:before {
  content: "\e9df";
}
.icon-calendar-day-solid:before {
  content: "\e9e0";
}
.icon-calendar-minus:before {
  content: "\e9e1";
}
.icon-calendar-minus-solid:before {
  content: "\e9e2";
}
.icon-calendar-plus:before {
  content: "\e9e3";
}
.icon-calendar-plus-solid:before {
  content: "\e9e4";
}
.icon-calendar-solid:before {
  content: "\e9e5";
}
.icon-calendar-times:before {
  content: "\e9e6";
}
.icon-calendar-times-solid:before {
  content: "\e9e7";
}
.icon-calendar-week-solid:before {
  content: "\e9e8";
}
.icon-camera-retro-solid:before {
  content: "\e9e9";
}
.icon-camera-solid:before {
  content: "\e9ea";
}
.icon-campground-solid:before {
  content: "\e9eb";
}
.icon-canadian-maple-leaf:before {
  content: "\e9ec";
}
.icon-candy-cane-solid:before {
  content: "\e9ed";
}
.icon-cannabis-solid:before {
  content: "\e9ee";
}
.icon-capsules-solid:before {
  content: "\e9ef";
}
.icon-car-alt-solid:before {
  content: "\e9f0";
}
.icon-car-battery-solid:before {
  content: "\e9f1";
}
.icon-car-crash-solid:before {
  content: "\e9f2";
}
.icon-caret-down-solid:before {
  content: "\e9f3";
}
.icon-caret-left-solid:before {
  content: "\e9f4";
}
.icon-caret-right-solid:before {
  content: "\e9f5";
}
.icon-caret-square-down:before {
  content: "\e9f6";
}
.icon-caret-square-down-solid:before {
  content: "\e9f7";
}
.icon-caret-square-left:before {
  content: "\e9f8";
}
.icon-caret-square-left-solid:before {
  content: "\e9f9";
}
.icon-caret-square-right:before {
  content: "\e9fa";
}
.icon-caret-square-right-solid:before {
  content: "\e9fb";
}
.icon-caret-square-up:before {
  content: "\e9fc";
}
.icon-caret-square-up-solid:before {
  content: "\e9fd";
}
.icon-caret-up-solid:before {
  content: "\e9fe";
}
.icon-carrot-solid:before {
  content: "\e9ff";
}
.icon-car-side-solid:before {
  content: "\ea00";
}
.icon-car-solid:before {
  content: "\ea01";
}
.icon-cart-arrow-down-solid:before {
  content: "\ea02";
}
.icon-cart-plus-solid:before {
  content: "\ea03";
}
.icon-cash-register-solid:before {
  content: "\ea04";
}
.icon-cat-solid:before {
  content: "\ea05";
}
.icon-cc-amazon-pay:before {
  content: "\ea06";
}
.icon-cc-amex:before {
  content: "\ea07";
}
.icon-cc-apple-pay:before {
  content: "\ea08";
}
.icon-cc-diners-club:before {
  content: "\ea09";
}
.icon-cc-discover:before {
  content: "\ea0a";
}
.icon-cc-jcb:before {
  content: "\ea0b";
}
.icon-cc-mastercard:before {
  content: "\ea0c";
}
.icon-cc-paypal:before {
  content: "\ea0d";
}
.icon-cc-stripe:before {
  content: "\ea0e";
}
.icon-cc-visa:before {
  content: "\ea0f";
}
.icon-centercode:before {
  content: "\ea10";
}
.icon-centos:before {
  content: "\ea11";
}
.icon-certificate-solid:before {
  content: "\ea12";
}
.icon-chair-solid:before {
  content: "\ea13";
}
.icon-chalkboard-solid:before {
  content: "\ea14";
}
.icon-chalkboard-teacher-solid:before {
  content: "\ea15";
}
.icon-charging-station-solid:before {
  content: "\ea16";
}
.icon-chart-area-solid:before {
  content: "\ea17";
}
.icon-chart-bar:before {
  content: "\ea18";
}
.icon-chart-bar-solid:before {
  content: "\ea19";
}
.icon-chart-line-solid:before {
  content: "\ea1a";
}
.icon-chart-pie-solid:before {
  content: "\ea1b";
}
.icon-check-circle:before {
  content: "\ea1c";
}
.icon-check-circle-solid:before {
  content: "\ea1d";
}
.icon-check-double-solid:before {
  content: "\ea1e";
}
.icon-check-solid:before {
  content: "\ea1f";
}
.icon-check-square:before {
  content: "\ea20";
}
.icon-check-square-solid:before {
  content: "\ea21";
}
.icon-cheese-solid:before {
  content: "\ea22";
}
.icon-chess-bishop-solid:before {
  content: "\ea23";
}
.icon-chess-board-solid:before {
  content: "\ea24";
}
.icon-chess-king-solid:before {
  content: "\ea25";
}
.icon-chess-knight-solid:before {
  content: "\ea26";
}
.icon-chess-pawn-solid:before {
  content: "\ea27";
}
.icon-chess-queen-solid:before {
  content: "\ea28";
}
.icon-chess-rook-solid:before {
  content: "\ea29";
}
.icon-chess-solid:before {
  content: "\ea2a";
}
.icon-chevron-circle-down-solid:before {
  content: "\ea2b";
}
.icon-chevron-circle-left-solid:before {
  content: "\ea2c";
}
.icon-chevron-circle-right-solid:before {
  content: "\ea2d";
}
.icon-chevron-circle-up-solid:before {
  content: "\ea2e";
}
.icon-chevron-down-solid:before {
  content: "\ea2f";
}
.icon-chevron-left-solid:before {
  content: "\ea30";
}
.icon-chevron-right-solid:before {
  content: "\ea31";
}
.icon-chevron-up-solid:before {
  content: "\ea32";
}
.icon-child-solid:before {
  content: "\ea33";
}
.icon-chrome:before {
  content: "\ea34";
}
.icon-chromecast:before {
  content: "\ea35";
}
.icon-church-solid:before {
  content: "\ea36";
}
.icon-circle1:before {
  content: "\ea37";
}
.icon-circle-notch-solid:before {
  content: "\ea38";
}
.icon-circle-solid:before {
  content: "\ea39";
}
.icon-city-solid:before {
  content: "\ea3a";
}
.icon-clinic-medical-solid:before {
  content: "\ea3b";
}
.icon-clipboard1:before {
  content: "\ea3c";
}
.icon-clipboard-check-solid:before {
  content: "\ea3d";
}
.icon-clipboard-list-solid:before {
  content: "\ea3e";
}
.icon-clipboard-solid:before {
  content: "\ea3f";
}
.icon-clock:before {
  content: "\ea40";
}
.icon-clock-solid:before {
  content: "\ea41";
}
.icon-clone:before {
  content: "\ea42";
}
.icon-clone-solid:before {
  content: "\ea43";
}
.icon-closed-captioning:before {
  content: "\ea44";
}
.icon-closed-captioning-solid:before {
  content: "\ea45";
}
.icon-cloud-download-alt-solid:before {
  content: "\ea46";
}
.icon-cloud-meatball-solid:before {
  content: "\ea47";
}
.icon-cloud-moon-rain-solid:before {
  content: "\ea48";
}
.icon-cloud-moon-solid:before {
  content: "\ea49";
}
.icon-cloud-rain-solid:before {
  content: "\ea4a";
}
.icon-cloudscale:before {
  content: "\ea4b";
}
.icon-cloud-showers-heavy-solid:before {
  content: "\ea4c";
}
.icon-cloudsmith:before {
  content: "\ea4d";
}
.icon-cloud-solid:before {
  content: "\ea4e";
}
.icon-cloud-sun-rain-solid:before {
  content: "\ea4f";
}
.icon-cloud-sun-solid:before {
  content: "\ea50";
}
.icon-cloud-upload-alt-solid:before {
  content: "\ea51";
}
.icon-cloudversify:before {
  content: "\ea52";
}
.icon-cocktail-solid:before {
  content: "\ea53";
}
.icon-code-branch-solid:before {
  content: "\ea54";
}
.icon-codepen:before {
  content: "\ea55";
}
.icon-code-solid:before {
  content: "\ea56";
}
.icon-codiepie:before {
  content: "\ea57";
}
.icon-coffee-solid:before {
  content: "\ea58";
}
.icon-cog-solid:before {
  content: "\ea59";
}
.icon-cogs-solid:before {
  content: "\ea5a";
}
.icon-coins-solid:before {
  content: "\ea5b";
}
.icon-columns-solid:before {
  content: "\ea5c";
}
.icon-comment:before {
  content: "\ea5d";
}
.icon-comment-alt:before {
  content: "\ea5e";
}
.icon-comment-alt-solid:before {
  content: "\ea5f";
}
.icon-comment-dollar-solid:before {
  content: "\ea60";
}
.icon-comment-dots:before {
  content: "\ea61";
}
.icon-comment-dots-solid:before {
  content: "\ea62";
}
.icon-comment-medical-solid:before {
  content: "\ea63";
}
.icon-comments:before {
  content: "\ea64";
}
.icon-comments-dollar-solid:before {
  content: "\ea65";
}
.icon-comment-slash-solid:before {
  content: "\ea66";
}
.icon-comment-solid:before {
  content: "\ea67";
}
.icon-comments-solid:before {
  content: "\ea68";
}
.icon-compact-disc-solid:before {
  content: "\ea69";
}
.icon-compass:before {
  content: "\ea6a";
}
.icon-compass-solid:before {
  content: "\ea6b";
}
.icon-compress-arrows-alt-solid:before {
  content: "\ea6c";
}
.icon-compress-solid:before {
  content: "\ea6d";
}
.icon-concierge-bell-solid:before {
  content: "\ea6e";
}
.icon-confluence:before {
  content: "\ea6f";
}
.icon-connectdevelop:before {
  content: "\ea70";
}
.icon-contao:before {
  content: "\ea71";
}
.icon-cookie-bite-solid:before {
  content: "\ea72";
}
.icon-cookie-solid:before {
  content: "\ea73";
}
.icon-copy:before {
  content: "\ea74";
}
.icon-copyright:before {
  content: "\ea75";
}
.icon-copyright-solid:before {
  content: "\ea76";
}
.icon-copy-solid:before {
  content: "\ea77";
}
.icon-cotton-bureau:before {
  content: "\ea78";
}
.icon-couch-solid:before {
  content: "\ea79";
}
.icon-cpanel:before {
  content: "\ea7a";
}
.icon-creative-commons:before {
  content: "\ea7b";
}
.icon-creative-commons-by:before {
  content: "\ea7c";
}
.icon-creative-commons-nc:before {
  content: "\ea7d";
}
.icon-creative-commons-nc-eu:before {
  content: "\ea7e";
}
.icon-creative-commons-nc-jp:before {
  content: "\ea7f";
}
.icon-creative-commons-nd:before {
  content: "\ea80";
}
.icon-creative-commons-pd:before {
  content: "\ea81";
}
.icon-creative-commons-pd-alt:before {
  content: "\ea82";
}
.icon-creative-commons-remix:before {
  content: "\ea83";
}
.icon-creative-commons-sa:before {
  content: "\ea84";
}
.icon-creative-commons-sampling:before {
  content: "\ea85";
}
.icon-creative-commons-sampling-plus:before {
  content: "\ea86";
}
.icon-creative-commons-share:before {
  content: "\ea87";
}
.icon-creative-commons-zero:before {
  content: "\ea88";
}
.icon-credit-card:before {
  content: "\ea89";
}
.icon-credit-card-solid:before {
  content: "\ea8a";
}
.icon-critical-role:before {
  content: "\ea8b";
}
.icon-crop-alt-solid:before {
  content: "\ea8c";
}
.icon-crop-solid:before {
  content: "\ea8d";
}
.icon-crosshairs-solid:before {
  content: "\ea8e";
}
.icon-cross-solid:before {
  content: "\ea8f";
}
.icon-crown-solid:before {
  content: "\ea90";
}
.icon-crow-solid:before {
  content: "\ea91";
}
.icon-crutch-solid:before {
  content: "\ea92";
}
.icon-css3:before {
  content: "\ea93";
}
.icon-css3-alt:before {
  content: "\ea94";
}
.icon-cube-solid:before {
  content: "\ea95";
}
.icon-cubes-solid:before {
  content: "\ea96";
}
.icon-cut-solid:before {
  content: "\ea97";
}
.icon-cuttlefish:before {
  content: "\ea98";
}
.icon-d-and-d:before {
  content: "\ea99";
}
.icon-d-and-d-beyond:before {
  content: "\ea9a";
}
.icon-dashcube:before {
  content: "\ea9b";
}
.icon-database-solid:before {
  content: "\ea9c";
}
.icon-deaf-solid:before {
  content: "\ea9d";
}
.icon-delicious:before {
  content: "\ea9e";
}
.icon-democrat-solid:before {
  content: "\ea9f";
}
.icon-deploydog:before {
  content: "\eaa0";
}
.icon-deskpro:before {
  content: "\eaa1";
}
.icon-desktop-solid:before {
  content: "\eaa2";
}
.icon-dev:before {
  content: "\eaa3";
}
.icon-deviantart:before {
  content: "\eaa4";
}
.icon-dharmachakra-solid:before {
  content: "\eaa5";
}
.icon-dhl:before {
  content: "\eaa6";
}
.icon-diagnoses-solid:before {
  content: "\eaa7";
}
.icon-diaspora:before {
  content: "\eaa8";
}
.icon-dice-d6-solid:before {
  content: "\eaa9";
}
.icon-dice-d20-solid:before {
  content: "\eaaa";
}
.icon-dice-five-solid:before {
  content: "\eaab";
}
.icon-dice-four-solid:before {
  content: "\eaac";
}
.icon-dice-one-solid:before {
  content: "\eaad";
}
.icon-dice-six-solid:before {
  content: "\eaae";
}
.icon-dice-solid:before {
  content: "\eaaf";
}
.icon-dice-three-solid:before {
  content: "\eab0";
}
.icon-dice-two-solid:before {
  content: "\eab1";
}
.icon-digg:before {
  content: "\eab2";
}
.icon-digital-ocean:before {
  content: "\eab3";
}
.icon-digital-tachograph-solid:before {
  content: "\eab4";
}
.icon-directions-solid:before {
  content: "\eab5";
}
.icon-discord:before {
  content: "\eab6";
}
.icon-discourse:before {
  content: "\eab7";
}
.icon-divide-solid:before {
  content: "\eab8";
}
.icon-dizzy:before {
  content: "\eab9";
}
.icon-dizzy-solid:before {
  content: "\eaba";
}
.icon-dna-solid:before {
  content: "\eabb";
}
.icon-dochub:before {
  content: "\eabc";
}
.icon-docker:before {
  content: "\eabd";
}
.icon-dog-solid:before {
  content: "\eabe";
}
.icon-dollar-sign-solid:before {
  content: "\eabf";
}
.icon-dolly-flatbed-solid:before {
  content: "\eac0";
}
.icon-dolly-solid:before {
  content: "\eac1";
}
.icon-donate-solid:before {
  content: "\eac2";
}
.icon-door-closed-solid:before {
  content: "\eac3";
}
.icon-door-open-solid:before {
  content: "\eac4";
}
.icon-dot-circle:before {
  content: "\eac5";
}
.icon-dot-circle-solid:before {
  content: "\eac6";
}
.icon-dove-solid:before {
  content: "\eac7";
}
.icon-download-solid:before {
  content: "\eac8";
}
.icon-draft2digital:before {
  content: "\eac9";
}
.icon-drafting-compass-solid:before {
  content: "\eaca";
}
.icon-dragon-solid:before {
  content: "\eacb";
}
.icon-draw-polygon-solid:before {
  content: "\eacc";
}
.icon-dribbble:before {
  content: "\eacd";
}
.icon-dribbble-square:before {
  content: "\eace";
}
.icon-dropbox:before {
  content: "\eacf";
}
.icon-drum-solid:before {
  content: "\ead0";
}
.icon-drum-steelpan-solid:before {
  content: "\ead1";
}
.icon-drumstick-bite-solid:before {
  content: "\ead2";
}
.icon-drupal:before {
  content: "\ead3";
}
.icon-dumbbell-solid:before {
  content: "\ead4";
}
.icon-dumpster-fire-solid:before {
  content: "\ead5";
}
.icon-dumpster-solid:before {
  content: "\ead6";
}
.icon-dungeon-solid:before {
  content: "\ead7";
}
.icon-dyalog:before {
  content: "\ead8";
}
.icon-earlybirds:before {
  content: "\ead9";
}
.icon-ebay:before {
  content: "\eada";
}
.icon-edge:before {
  content: "\eadb";
}
.icon-edit:before {
  content: "\eadc";
}
.icon-edit-solid:before {
  content: "\eadd";
}
.icon-egg-solid:before {
  content: "\eade";
}
.icon-eject-solid:before {
  content: "\eadf";
}
.icon-elementor:before {
  content: "\eae0";
}
.icon-ellipsis-h-solid:before {
  content: "\eae1";
}
.icon-ellipsis-v-solid:before {
  content: "\eae2";
}
.icon-ello:before {
  content: "\eae3";
}
.icon-ember:before {
  content: "\eae4";
}
.icon-empire:before {
  content: "\eae5";
}
.icon-envelope:before {
  content: "\eae6";
}
.icon-envelope-open:before {
  content: "\eae7";
}
.icon-envelope-open-solid:before {
  content: "\eae8";
}
.icon-envelope-open-text-solid:before {
  content: "\eae9";
}
.icon-envelope-solid:before {
  content: "\eaea";
}
.icon-envelope-square-solid:before {
  content: "\eaeb";
}
.icon-envira:before {
  content: "\eaec";
}
.icon-equals-solid:before {
  content: "\eaed";
}
.icon-eraser-solid:before {
  content: "\eaee";
}
.icon-erlang:before {
  content: "\eaef";
}
.icon-ethereum:before {
  content: "\eaf0";
}
.icon-ethernet-solid:before {
  content: "\eaf1";
}
.icon-etsy:before {
  content: "\eaf2";
}
.icon-euro-sign-solid:before {
  content: "\eaf3";
}
.icon-evernote:before {
  content: "\eaf4";
}
.icon-exchange-alt-solid:before {
  content: "\eaf5";
}
.icon-exclamation-circle-solid:before {
  content: "\eaf6";
}
.icon-exclamation-solid:before {
  content: "\eaf7";
}
.icon-exclamation-triangle-solid:before {
  content: "\eaf8";
}
.icon-expand-arrows-alt-solid:before {
  content: "\eaf9";
}
.icon-expand-solid:before {
  content: "\eafa";
}
.icon-expeditedssl:before {
  content: "\eafb";
}
.icon-external-link-alt-solid:before {
  content: "\eafc";
}
.icon-external-link-square-alt-solid:before {
  content: "\eafd";
}
.icon-eye:before {
  content: "\eafe";
}
.icon-eye-dropper-solid:before {
  content: "\eaff";
}
.icon-eye-slash:before {
  content: "\eb00";
}
.icon-eye-slash-solid:before {
  content: "\eb01";
}
.icon-eye-solid:before {
  content: "\eb02";
}
.icon-facebook:before {
  content: "\eb03";
}
.icon-facebook-f:before {
  content: "\eb04";
}
.icon-facebook-messenger:before {
  content: "\eb05";
}
.icon-facebook-square:before {
  content: "\eb06";
}
.icon-fan-solid:before {
  content: "\eb07";
}
.icon-fantasy-flight-games:before {
  content: "\eb08";
}
.icon-fast-backward-solid:before {
  content: "\eb09";
}
.icon-fast-forward-solid:before {
  content: "\eb0a";
}
.icon-fax-solid:before {
  content: "\eb0b";
}
.icon-feather-alt-solid:before {
  content: "\eb0c";
}
.icon-feather-solid:before {
  content: "\eb0d";
}
.icon-fedex:before {
  content: "\eb0e";
}
.icon-fedora:before {
  content: "\eb0f";
}
.icon-female-solid:before {
  content: "\eb10";
}
.icon-fighter-jet-solid:before {
  content: "\eb11";
}
.icon-figma:before {
  content: "\eb12";
}
.icon-file:before {
  content: "\eb13";
}
.icon-file-alt:before {
  content: "\eb14";
}
.icon-file-alt-solid:before {
  content: "\eb15";
}
.icon-file-archive:before {
  content: "\eb16";
}
.icon-file-archive-solid:before {
  content: "\eb17";
}
.icon-file-audio:before {
  content: "\eb18";
}
.icon-file-audio-solid:before {
  content: "\eb19";
}
.icon-file-code:before {
  content: "\eb1a";
}
.icon-file-code-solid:before {
  content: "\eb1b";
}
.icon-file-contract-solid:before {
  content: "\eb1c";
}
.icon-file-csv-solid:before {
  content: "\eb1d";
}
.icon-file-download-solid:before {
  content: "\eb1e";
}
.icon-file-excel:before {
  content: "\eb1f";
}
.icon-file-excel-solid:before {
  content: "\eb20";
}
.icon-file-export-solid:before {
  content: "\eb21";
}
.icon-file-image:before {
  content: "\eb22";
}
.icon-file-image-solid:before {
  content: "\eb23";
}
.icon-file-import-solid:before {
  content: "\eb24";
}
.icon-file-invoice-dollar-solid:before {
  content: "\eb25";
}
.icon-file-invoice-solid:before {
  content: "\eb26";
}
.icon-file-medical-alt-solid:before {
  content: "\eb27";
}
.icon-file-medical-solid:before {
  content: "\eb28";
}
.icon-file-pdf:before {
  content: "\eb29";
}
.icon-file-pdf-solid:before {
  content: "\eb2a";
}
.icon-file-powerpoint:before {
  content: "\eb2b";
}
.icon-file-powerpoint-solid:before {
  content: "\eb2c";
}
.icon-file-prescription-solid:before {
  content: "\eb2d";
}
.icon-file-signature-solid:before {
  content: "\eb2e";
}
.icon-file-solid:before {
  content: "\eb2f";
}
.icon-file-upload-solid:before {
  content: "\eb30";
}
.icon-file-video:before {
  content: "\eb31";
}
.icon-file-video-solid:before {
  content: "\eb32";
}
.icon-file-word:before {
  content: "\eb33";
}
.icon-file-word-solid:before {
  content: "\eb34";
}
.icon-fill-drip-solid:before {
  content: "\eb35";
}
.icon-fill-solid:before {
  content: "\eb36";
}
.icon-film-solid:before {
  content: "\eb37";
}
.icon-filter-solid:before {
  content: "\eb38";
}
.icon-fingerprint-solid:before {
  content: "\eb39";
}
.icon-fire-alt-solid:before {
  content: "\eb3a";
}
.icon-fire-extinguisher-solid:before {
  content: "\eb3b";
}
.icon-firefox:before {
  content: "\eb3c";
}
.icon-fire-solid:before {
  content: "\eb3d";
}
.icon-first-aid-solid:before {
  content: "\eb3e";
}
.icon-firstdraft:before {
  content: "\eb3f";
}
.icon-first-order:before {
  content: "\eb40";
}
.icon-first-order-alt:before {
  content: "\eb41";
}
.icon-fish-solid:before {
  content: "\eb42";
}
.icon-fist-raised-solid:before {
  content: "\eb43";
}
.icon-flag:before {
  content: "\eb44";
}
.icon-flag-checkered-solid:before {
  content: "\eb45";
}
.icon-flag-solid:before {
  content: "\eb46";
}
.icon-flag-usa-solid:before {
  content: "\eb47";
}
.icon-flask-solid:before {
  content: "\eb48";
}
.icon-flickr:before {
  content: "\eb49";
}
.icon-flipboard:before {
  content: "\eb4a";
}
.icon-flushed:before {
  content: "\eb4b";
}
.icon-flushed-solid:before {
  content: "\eb4c";
}
.icon-fly:before {
  content: "\eb4d";
}
.icon-folder1:before {
  content: "\eb4e";
}
.icon-folder-minus-solid:before {
  content: "\eb4f";
}
.icon-folder-open:before {
  content: "\eb50";
}
.icon-folder-open-solid:before {
  content: "\eb51";
}
.icon-folder-plus-solid:before {
  content: "\eb52";
}
.icon-folder-solid:before {
  content: "\eb53";
}
.icon-font-awesome:before {
  content: "\eb54";
}
.icon-font-awesome-alt:before {
  content: "\eb55";
}
.icon-font-awesome-flag:before {
  content: "\eb56";
}
.icon-fonticons:before {
  content: "\eb57";
}
.icon-fonticons-fi:before {
  content: "\eb58";
}
.icon-font-solid:before {
  content: "\eb59";
}
.icon-football-ball-solid:before {
  content: "\eb5a";
}
.icon-fort-awesome:before {
  content: "\eb5b";
}
.icon-fort-awesome-alt:before {
  content: "\eb5c";
}
.icon-forumbee:before {
  content: "\eb5d";
}
.icon-forward-solid:before {
  content: "\eb5e";
}
.icon-foursquare:before {
  content: "\eb5f";
}
.icon-freebsd:before {
  content: "\eb60";
}
.icon-free-code-camp:before {
  content: "\eb61";
}
.icon-frog-solid:before {
  content: "\eb62";
}
.icon-frown:before {
  content: "\eb63";
}
.icon-frown-open:before {
  content: "\eb64";
}
.icon-frown-open-solid:before {
  content: "\eb65";
}
.icon-frown-solid:before {
  content: "\eb66";
}
.icon-fulcrum:before {
  content: "\eb67";
}
.icon-funnel-dollar-solid:before {
  content: "\eb68";
}
.icon-futbol:before {
  content: "\eb69";
}
.icon-futbol-solid:before {
  content: "\eb6a";
}
.icon-galactic-republic:before {
  content: "\eb6b";
}
.icon-galactic-senate:before {
  content: "\eb6c";
}
.icon-gamepad-solid:before {
  content: "\eb6d";
}
.icon-gas-pump-solid:before {
  content: "\eb6e";
}
.icon-gavel-solid:before {
  content: "\eb6f";
}
.icon-gem:before {
  content: "\eb70";
}
.icon-gem-solid:before {
  content: "\eb71";
}
.icon-genderless-solid:before {
  content: "\eb72";
}
.icon-get-pocket:before {
  content: "\eb73";
}
.icon-gg:before {
  content: "\eb74";
}
.icon-gg-circle:before {
  content: "\eb75";
}
.icon-ghost-solid:before {
  content: "\eb76";
}
.icon-gift-solid:before {
  content: "\eb77";
}
.icon-gifts-solid:before {
  content: "\eb78";
}
.icon-git:before {
  content: "\eb79";
}
.icon-git-alt:before {
  content: "\eb7a";
}
.icon-github:before {
  content: "\eb7b";
}
.icon-github-alt:before {
  content: "\eb7c";
}
.icon-github-square:before {
  content: "\eb7d";
}
.icon-gitkraken:before {
  content: "\eb7e";
}
.icon-gitlab:before {
  content: "\eb7f";
}
.icon-git-square:before {
  content: "\eb80";
}
.icon-gitter:before {
  content: "\eb81";
}
.icon-glass-cheers-solid:before {
  content: "\eb82";
}
.icon-glasses-solid:before {
  content: "\eb83";
}
.icon-glass-martini-alt-solid:before {
  content: "\eb84";
}
.icon-glass-martini-solid:before {
  content: "\eb85";
}
.icon-glass-whiskey-solid:before {
  content: "\eb86";
}
.icon-glide:before {
  content: "\eb87";
}
.icon-glide-g:before {
  content: "\eb88";
}
.icon-globe-africa-solid:before {
  content: "\eb89";
}
.icon-globe-americas-solid:before {
  content: "\eb8a";
}
.icon-globe-asia-solid:before {
  content: "\eb8b";
}
.icon-globe-europe-solid:before {
  content: "\eb8c";
}
.icon-globe-solid:before {
  content: "\eb8d";
}
.icon-gofore:before {
  content: "\eb8e";
}
.icon-golf-ball-solid:before {
  content: "\eb8f";
}
.icon-goodreads:before {
  content: "\eb90";
}
.icon-goodreads-g:before {
  content: "\eb91";
}
.icon-google:before {
  content: "\eb92";
}
.icon-google-drive:before {
  content: "\eb93";
}
.icon-google-play:before {
  content: "\eb94";
}
.icon-google-plus:before {
  content: "\eb95";
}
.icon-google-plus-g:before {
  content: "\eb96";
}
.icon-google-plus-square:before {
  content: "\eb97";
}
.icon-google-wallet:before {
  content: "\eb98";
}
.icon-gopuram-solid:before {
  content: "\eb99";
}
.icon-graduation-cap-solid:before {
  content: "\eb9a";
}
.icon-gratipay:before {
  content: "\eb9b";
}
.icon-grav:before {
  content: "\eb9c";
}
.icon-greater-than-equal-solid:before {
  content: "\eb9d";
}
.icon-greater-than-solid:before {
  content: "\eb9e";
}
.icon-grimace:before {
  content: "\eb9f";
}
.icon-grimace-solid:before {
  content: "\eba0";
}
.icon-grin:before {
  content: "\eba1";
}
.icon-grin-alt:before {
  content: "\eba2";
}
.icon-grin-alt-solid:before {
  content: "\eba3";
}
.icon-grin-beam:before {
  content: "\eba4";
}
.icon-grin-beam-solid:before {
  content: "\eba5";
}
.icon-grin-beam-sweat:before {
  content: "\eba6";
}
.icon-grin-beam-sweat-solid:before {
  content: "\eba7";
}
.icon-grin-hearts:before {
  content: "\eba8";
}
.icon-grin-hearts-solid:before {
  content: "\eba9";
}
.icon-grin-solid:before {
  content: "\ebaa";
}
.icon-grin-squint:before {
  content: "\ebab";
}
.icon-grin-squint-solid:before {
  content: "\ebac";
}
.icon-grin-squint-tears:before {
  content: "\ebad";
}
.icon-grin-squint-tears-solid:before {
  content: "\ebae";
}
.icon-grin-stars:before {
  content: "\ebaf";
}
.icon-grin-stars-solid:before {
  content: "\ebb0";
}
.icon-grin-tears:before {
  content: "\ebb1";
}
.icon-grin-tears-solid:before {
  content: "\ebb2";
}
.icon-grin-tongue:before {
  content: "\ebb3";
}
.icon-grin-tongue-solid:before {
  content: "\ebb4";
}
.icon-grin-tongue-squint:before {
  content: "\ebb5";
}
.icon-grin-tongue-squint-solid:before {
  content: "\ebb6";
}
.icon-grin-tongue-wink:before {
  content: "\ebb7";
}
.icon-grin-tongue-wink-solid:before {
  content: "\ebb8";
}
.icon-grin-wink:before {
  content: "\ebb9";
}
.icon-grin-wink-solid:before {
  content: "\ebba";
}
.icon-gripfire:before {
  content: "\ebbb";
}
.icon-grip-horizontal-solid:before {
  content: "\ebbc";
}
.icon-grip-lines-solid:before {
  content: "\ebbd";
}
.icon-grip-lines-vertical-solid:before {
  content: "\ebbe";
}
.icon-grip-vertical-solid:before {
  content: "\ebbf";
}
.icon-grunt:before {
  content: "\ebc0";
}
.icon-guitar-solid:before {
  content: "\ebc1";
}
.icon-gulp:before {
  content: "\ebc2";
}
.icon-hacker-news:before {
  content: "\ebc3";
}
.icon-hacker-news-square:before {
  content: "\ebc4";
}
.icon-hackerrank:before {
  content: "\ebc5";
}
.icon-hamburger-solid:before {
  content: "\ebc6";
}
.icon-hammer-solid:before {
  content: "\ebc7";
}
.icon-hamsa-solid:before {
  content: "\ebc8";
}
.icon-hand-holding-heart-solid:before {
  content: "\ebc9";
}
.icon-hand-holding-solid:before {
  content: "\ebca";
}
.icon-hand-holding-usd-solid:before {
  content: "\ebcb";
}
.icon-hand-lizard:before {
  content: "\ebcc";
}
.icon-hand-lizard-solid:before {
  content: "\ebcd";
}
.icon-hand-middle-finger-solid:before {
  content: "\ebce";
}
.icon-hand-paper:before {
  content: "\ebcf";
}
.icon-hand-paper-solid:before {
  content: "\ebd0";
}
.icon-hand-peace:before {
  content: "\ebd1";
}
.icon-hand-peace-solid:before {
  content: "\ebd2";
}
.icon-hand-point-down:before {
  content: "\ebd3";
}
.icon-hand-point-down-solid:before {
  content: "\ebd4";
}
.icon-hand-pointer:before {
  content: "\ebd5";
}
.icon-hand-pointer-solid:before {
  content: "\ebd6";
}
.icon-hand-point-left:before {
  content: "\ebd7";
}
.icon-hand-point-left-solid:before {
  content: "\ebd8";
}
.icon-hand-point-right:before {
  content: "\ebd9";
}
.icon-hand-point-right-solid:before {
  content: "\ebda";
}
.icon-hand-point-up:before {
  content: "\ebdb";
}
.icon-hand-point-up-solid:before {
  content: "\ebdc";
}
.icon-hand-rock:before {
  content: "\ebdd";
}
.icon-hand-rock-solid:before {
  content: "\ebde";
}
.icon-hand-scissors:before {
  content: "\ebdf";
}
.icon-hand-scissors-solid:before {
  content: "\ebe0";
}
.icon-handshake:before {
  content: "\ebe1";
}
.icon-handshake-solid:before {
  content: "\ebe2";
}
.icon-hands-helping-solid:before {
  content: "\ebe3";
}
.icon-hand-spock:before {
  content: "\ebe4";
}
.icon-hand-spock-solid:before {
  content: "\ebe5";
}
.icon-hands-solid:before {
  content: "\ebe6";
}
.icon-hanukiah-solid:before {
  content: "\ebe7";
}
.icon-hard-hat-solid:before {
  content: "\ebe8";
}
.icon-hashtag-solid:before {
  content: "\ebe9";
}
.icon-hat-cowboy-side-solid:before {
  content: "\ebea";
}
.icon-hat-cowboy-solid:before {
  content: "\ebeb";
}
.icon-hat-wizard-solid:before {
  content: "\ebec";
}
.icon-haykal-solid:before {
  content: "\ebed";
}
.icon-hdd:before {
  content: "\ebee";
}
.icon-hdd-solid:before {
  content: "\ebef";
}
.icon-heading-solid:before {
  content: "\ebf0";
}
.icon-headphones-alt-solid:before {
  content: "\ebf1";
}
.icon-headphones-solid:before {
  content: "\ebf2";
}
.icon-headset-solid:before {
  content: "\ebf3";
}
.icon-heart:before {
  content: "\ebf4";
}
.icon-heartbeat-solid:before {
  content: "\ebf5";
}
.icon-heart-broken-solid:before {
  content: "\ebf6";
}
.icon-heart-solid:before {
  content: "\ebf7";
}
.icon-helicopter-solid:before {
  content: "\ebf8";
}
.icon-highlighter-solid:before {
  content: "\ebf9";
}
.icon-hiking-solid:before {
  content: "\ebfa";
}
.icon-hippo-solid:before {
  content: "\ebfb";
}
.icon-hips:before {
  content: "\ebfc";
}
.icon-hire-a-helper:before {
  content: "\ebfd";
}
.icon-history-solid:before {
  content: "\ebfe";
}
.icon-hockey-puck-solid:before {
  content: "\ebff";
}
.icon-holly-berry-solid:before {
  content: "\ec00";
}
.icon-home-solid:before {
  content: "\ec01";
}
.icon-hooli:before {
  content: "\ec02";
}
.icon-hornbill:before {
  content: "\ec03";
}
.icon-horse-head-solid:before {
  content: "\ec04";
}
.icon-horse-solid:before {
  content: "\ec05";
}
.icon-hospital:before {
  content: "\ec06";
}
.icon-hospital-alt-solid:before {
  content: "\ec07";
}
.icon-hospital-solid:before {
  content: "\ec08";
}
.icon-hospital-symbol-solid:before {
  content: "\ec09";
}
.icon-hotdog-solid:before {
  content: "\ec0a";
}
.icon-hotel-solid:before {
  content: "\ec0b";
}
.icon-hotjar:before {
  content: "\ec0c";
}
.icon-hot-tub-solid:before {
  content: "\ec0d";
}
.icon-hourglass:before {
  content: "\ec0e";
}
.icon-hourglass-end-solid:before {
  content: "\ec0f";
}
.icon-hourglass-half-solid:before {
  content: "\ec10";
}
.icon-hourglass-solid:before {
  content: "\ec11";
}
.icon-hourglass-start-solid:before {
  content: "\ec12";
}
.icon-house-damage-solid:before {
  content: "\ec13";
}
.icon-houzz:before {
  content: "\ec14";
}
.icon-hryvnia-solid:before {
  content: "\ec15";
}
.icon-h-square-solid:before {
  content: "\ec16";
}
.icon-html5:before {
  content: "\ec17";
}
.icon-hubspot:before {
  content: "\ec18";
}
.icon-ice-cream-solid:before {
  content: "\ec19";
}
.icon-icicles-solid:before {
  content: "\ec1a";
}
.icon-icons-solid:before {
  content: "\ec1b";
}
.icon-i-cursor-solid:before {
  content: "\ec1c";
}
.icon-id-badge:before {
  content: "\ec1d";
}
.icon-id-badge-solid:before {
  content: "\ec1e";
}
.icon-id-card:before {
  content: "\ec1f";
}
.icon-id-card-alt-solid:before {
  content: "\ec20";
}
.icon-id-card-solid:before {
  content: "\ec21";
}
.icon-igloo-solid:before {
  content: "\ec22";
}
.icon-image:before {
  content: "\ec23";
}
.icon-images:before {
  content: "\ec24";
}
.icon-image-solid:before {
  content: "\ec25";
}
.icon-images-solid:before {
  content: "\ec26";
}
.icon-imdb:before {
  content: "\ec27";
}
.icon-inbox-solid:before {
  content: "\ec28";
}
.icon-indent-solid:before {
  content: "\ec29";
}
.icon-industry-solid:before {
  content: "\ec2a";
}
.icon-infinity-solid:before {
  content: "\ec2b";
}
.icon-info-circle-solid:before {
  content: "\ec2c";
}
.icon-info-solid:before {
  content: "\ec2d";
}
.icon-instagram:before {
  content: "\ec2e";
}
.icon-intercom:before {
  content: "\ec2f";
}
.icon-internet-explorer:before {
  content: "\ec30";
}
.icon-invision:before {
  content: "\ec31";
}
.icon-ioxhost:before {
  content: "\ec32";
}
.icon-italic-solid:before {
  content: "\ec33";
}
.icon-itch-io:before {
  content: "\ec34";
}
.icon-itunes:before {
  content: "\ec35";
}
.icon-itunes-note:before {
  content: "\ec36";
}
.icon-java:before {
  content: "\ec37";
}
.icon-jedi-order:before {
  content: "\ec38";
}
.icon-jedi-solid:before {
  content: "\ec39";
}
.icon-jenkins:before {
  content: "\ec3a";
}
.icon-jira:before {
  content: "\ec3b";
}
.icon-joget:before {
  content: "\ec3c";
}
.icon-joint-solid:before {
  content: "\ec3d";
}
.icon-joomla:before {
  content: "\ec3e";
}
.icon-journal-whills-solid:before {
  content: "\ec3f";
}
.icon-js:before {
  content: "\ec40";
}
.icon-jsfiddle:before {
  content: "\ec41";
}
.icon-js-square:before {
  content: "\ec42";
}
.icon-kaaba-solid:before {
  content: "\ec43";
}
.icon-kaggle:before {
  content: "\ec44";
}
.icon-keybase:before {
  content: "\ec45";
}
.icon-keyboard:before {
  content: "\ec46";
}
.icon-keyboard-solid:before {
  content: "\ec47";
}
.icon-keycdn:before {
  content: "\ec48";
}
.icon-key-solid:before {
  content: "\ec49";
}
.icon-khanda-solid:before {
  content: "\ec4a";
}
.icon-kickstarter:before {
  content: "\ec4b";
}
.icon-kickstarter-k:before {
  content: "\ec4c";
}
.icon-kiss:before {
  content: "\ec4d";
}
.icon-kiss-beam:before {
  content: "\ec4e";
}
.icon-kiss-beam-solid:before {
  content: "\ec4f";
}
.icon-kiss-solid:before {
  content: "\ec50";
}
.icon-kiss-wink-heart:before {
  content: "\ec51";
}
.icon-kiss-wink-heart-solid:before {
  content: "\ec52";
}
.icon-kiwi-bird-solid:before {
  content: "\ec53";
}
.icon-korvue:before {
  content: "\ec54";
}
.icon-landmark-solid:before {
  content: "\ec55";
}
.icon-language-solid:before {
  content: "\ec56";
}
.icon-laptop-code-solid:before {
  content: "\ec57";
}
.icon-laptop-medical-solid:before {
  content: "\ec58";
}
.icon-laptop-solid:before {
  content: "\ec59";
}
.icon-laravel:before {
  content: "\ec5a";
}
.icon-lastfm:before {
  content: "\ec5b";
}
.icon-lastfm-square:before {
  content: "\ec5c";
}
.icon-laugh:before {
  content: "\ec5d";
}
.icon-laugh-beam:before {
  content: "\ec5e";
}
.icon-laugh-beam-solid:before {
  content: "\ec5f";
}
.icon-laugh-solid:before {
  content: "\ec60";
}
.icon-laugh-squint:before {
  content: "\ec61";
}
.icon-laugh-squint-solid:before {
  content: "\ec62";
}
.icon-laugh-wink:before {
  content: "\ec63";
}
.icon-laugh-wink-solid:before {
  content: "\ec64";
}
.icon-layer-group-solid:before {
  content: "\ec65";
}
.icon-leaf-solid:before {
  content: "\ec66";
}
.icon-leanpub:before {
  content: "\ec67";
}
.icon-lemon:before {
  content: "\ec68";
}
.icon-lemon-solid:before {
  content: "\ec69";
}
.icon-less:before {
  content: "\ec6a";
}
.icon-less-than-equal-solid:before {
  content: "\ec6b";
}
.icon-less-than-solid:before {
  content: "\ec6c";
}
.icon-level-down-alt-solid:before {
  content: "\ec6d";
}
.icon-level-up-alt-solid:before {
  content: "\ec6e";
}
.icon-life-ring:before {
  content: "\ec6f";
}
.icon-life-ring-solid:before {
  content: "\ec70";
}
.icon-lightbulb:before {
  content: "\ec71";
}
.icon-lightbulb-solid:before {
  content: "\ec72";
}
.icon-line:before {
  content: "\ec73";
}
.icon-linkedin:before {
  content: "\ec74";
}
.icon-linkedin-in:before {
  content: "\ec75";
}
.icon-link-solid:before {
  content: "\ec76";
}
.icon-linode:before {
  content: "\ec77";
}
.icon-linux:before {
  content: "\ec78";
}
.icon-lira-sign-solid:before {
  content: "\ec79";
}
.icon-list-alt:before {
  content: "\ec7a";
}
.icon-list-alt-solid:before {
  content: "\ec7b";
}
.icon-list-ol-solid:before {
  content: "\ec7c";
}
.icon-list-solid:before {
  content: "\ec7d";
}
.icon-list-ul-solid:before {
  content: "\ec7e";
}
.icon-location-arrow-solid:before {
  content: "\ec7f";
}
.icon-lock-open-solid:before {
  content: "\ec80";
}
.icon-lock-solid:before {
  content: "\ec81";
}
.icon-long-arrow-alt-down-solid:before {
  content: "\ec82";
}
.icon-long-arrow-alt-left-solid:before {
  content: "\ec83";
}
.icon-long-arrow-alt-right-solid:before {
  content: "\ec84";
}
.icon-long-arrow-alt-up-solid:before {
  content: "\ec85";
}
.icon-low-vision-solid:before {
  content: "\ec86";
}
.icon-luggage-cart-solid:before {
  content: "\ec87";
}
.icon-lyft:before {
  content: "\ec88";
}
.icon-magento:before {
  content: "\ec89";
}
.icon-magic-solid:before {
  content: "\ec8a";
}
.icon-magnet-solid:before {
  content: "\ec8b";
}
.icon-mail-bulk-solid:before {
  content: "\ec8c";
}
.icon-mailchimp:before {
  content: "\ec8d";
}
.icon-male-solid:before {
  content: "\ec8e";
}
.icon-mandalorian:before {
  content: "\ec8f";
}
.icon-map:before {
  content: "\ec90";
}
.icon-map-marked-alt-solid:before {
  content: "\ec91";
}
.icon-map-marked-solid:before {
  content: "\ec92";
}
.icon-map-marker-alt-solid:before {
  content: "\ec93";
}
.icon-map-marker-solid:before {
  content: "\ec94";
}
.icon-map-pin-solid:before {
  content: "\ec95";
}
.icon-map-signs-solid:before {
  content: "\ec96";
}
.icon-map-solid:before {
  content: "\ec97";
}
.icon-markdown:before {
  content: "\ec98";
}
.icon-marker-solid:before {
  content: "\ec99";
}
.icon-mars-double-solid:before {
  content: "\ec9a";
}
.icon-mars-solid:before {
  content: "\ec9b";
}
.icon-mars-stroke-h-solid:before {
  content: "\ec9c";
}
.icon-mars-stroke-solid:before {
  content: "\ec9d";
}
.icon-mars-stroke-v-solid:before {
  content: "\ec9e";
}
.icon-mask-solid:before {
  content: "\ec9f";
}
.icon-mastodon:before {
  content: "\eca0";
}
.icon-maxcdn:before {
  content: "\eca1";
}
.icon-mdb:before {
  content: "\eca2";
}
.icon-medal-solid:before {
  content: "\eca3";
}
.icon-medapps:before {
  content: "\eca4";
}
.icon-medium:before {
  content: "\eca5";
}
.icon-medium-m:before {
  content: "\eca6";
}
.icon-medkit-solid:before {
  content: "\eca7";
}
.icon-medrt:before {
  content: "\eca8";
}
.icon-meetup:before {
  content: "\eca9";
}
.icon-megaport:before {
  content: "\ecaa";
}
.icon-meh:before {
  content: "\ecab";
}
.icon-meh-blank:before {
  content: "\ecac";
}
.icon-meh-blank-solid:before {
  content: "\ecad";
}
.icon-meh-rolling-eyes:before {
  content: "\ecae";
}
.icon-meh-rolling-eyes-solid:before {
  content: "\ecaf";
}
.icon-meh-solid:before {
  content: "\ecb0";
}
.icon-memory-solid:before {
  content: "\ecb1";
}
.icon-mendeley:before {
  content: "\ecb2";
}
.icon-menorah-solid:before {
  content: "\ecb3";
}
.icon-mercury-solid:before {
  content: "\ecb4";
}
.icon-meteor-solid:before {
  content: "\ecb5";
}
.icon-microchip-solid:before {
  content: "\ecb6";
}
.icon-microphone-alt-slash-solid:before {
  content: "\ecb7";
}
.icon-microphone-alt-solid:before {
  content: "\ecb8";
}
.icon-microphone-slash-solid:before {
  content: "\ecb9";
}
.icon-microphone-solid:before {
  content: "\ecba";
}
.icon-microscope-solid:before {
  content: "\ecbb";
}
.icon-microsoft:before {
  content: "\ecbc";
}
.icon-minus-circle-solid:before {
  content: "\ecbd";
}
.icon-minus-solid:before {
  content: "\ecbe";
}
.icon-minus-square:before {
  content: "\ecbf";
}
.icon-minus-square-solid:before {
  content: "\ecc0";
}
.icon-mitten-solid:before {
  content: "\ecc1";
}
.icon-mix:before {
  content: "\ecc2";
}
.icon-mixcloud:before {
  content: "\ecc3";
}
.icon-mizuni:before {
  content: "\ecc4";
}
.icon-mobile-alt-solid:before {
  content: "\ecc5";
}
.icon-mobile-solid:before {
  content: "\ecc6";
}
.icon-modx:before {
  content: "\ecc7";
}
.icon-monero:before {
  content: "\ecc8";
}
.icon-money-bill-alt:before {
  content: "\ecc9";
}
.icon-money-bill-alt-solid:before {
  content: "\ecca";
}
.icon-money-bill-solid:before {
  content: "\eccb";
}
.icon-money-bill-wave-alt-solid:before {
  content: "\eccc";
}
.icon-money-bill-wave-solid:before {
  content: "\eccd";
}
.icon-money-check-alt-solid:before {
  content: "\ecce";
}
.icon-money-check-solid:before {
  content: "\eccf";
}
.icon-monument-solid:before {
  content: "\ecd0";
}
.icon-moon:before {
  content: "\ecd1";
}
.icon-moon-solid:before {
  content: "\ecd2";
}
.icon-mortar-pestle-solid:before {
  content: "\ecd3";
}
.icon-mosque-solid:before {
  content: "\ecd4";
}
.icon-motorcycle-solid:before {
  content: "\ecd5";
}
.icon-mountain-solid:before {
  content: "\ecd6";
}
.icon-mouse-pointer-solid:before {
  content: "\ecd7";
}
.icon-mouse-solid:before {
  content: "\ecd8";
}
.icon-mug-hot-solid:before {
  content: "\ecd9";
}
.icon-music-solid:before {
  content: "\ecda";
}
.icon-napster:before {
  content: "\ecdb";
}
.icon-neos:before {
  content: "\ecdc";
}
.icon-network-wired-solid:before {
  content: "\ecdd";
}
.icon-neuter-solid:before {
  content: "\ecde";
}
.icon-newspaper:before {
  content: "\ecdf";
}
.icon-newspaper-solid:before {
  content: "\ece0";
}
.icon-nimblr:before {
  content: "\ece1";
}
.icon-node:before {
  content: "\ece2";
}
.icon-node-js:before {
  content: "\ece3";
}
.icon-not-equal-solid:before {
  content: "\ece4";
}
.icon-notes-medical-solid:before {
  content: "\ece5";
}
.icon-npm:before {
  content: "\ece6";
}
.icon-ns8:before {
  content: "\ece7";
}
.icon-nutritionix:before {
  content: "\ece8";
}
.icon-object-group:before {
  content: "\ece9";
}
.icon-object-group-solid:before {
  content: "\ecea";
}
.icon-object-ungroup:before {
  content: "\eceb";
}
.icon-object-ungroup-solid:before {
  content: "\ecec";
}
.icon-odnoklassniki:before {
  content: "\eced";
}
.icon-odnoklassniki-square:before {
  content: "\ecee";
}
.icon-oil-can-solid:before {
  content: "\ecef";
}
.icon-old-republic:before {
  content: "\ecf0";
}
.icon-om-solid:before {
  content: "\ecf1";
}
.icon-opencart:before {
  content: "\ecf2";
}
.icon-openid:before {
  content: "\ecf3";
}
.icon-opera:before {
  content: "\ecf4";
}
.icon-optin-monster:before {
  content: "\ecf5";
}
.icon-orcid:before {
  content: "\ecf6";
}
.icon-osi:before {
  content: "\ecf7";
}
.icon-otter-solid:before {
  content: "\ecf8";
}
.icon-outdent-solid:before {
  content: "\ecf9";
}
.icon-page4:before {
  content: "\ecfa";
}
.icon-pagelines:before {
  content: "\ecfb";
}
.icon-pager-solid:before {
  content: "\ecfc";
}
.icon-paint-brush-solid:before {
  content: "\ecfd";
}
.icon-paint-roller-solid:before {
  content: "\ecfe";
}
.icon-palette-solid:before {
  content: "\ecff";
}
.icon-palfed:before {
  content: "\ed00";
}
.icon-pallet-solid:before {
  content: "\ed01";
}
.icon-paperclip-solid:before {
  content: "\ed02";
}
.icon-paper-plane:before {
  content: "\ed03";
}
.icon-paper-plane-solid:before {
  content: "\ed04";
}
.icon-parachute-box-solid:before {
  content: "\ed05";
}
.icon-paragraph-solid:before {
  content: "\ed06";
}
.icon-parking-solid:before {
  content: "\ed07";
}
.icon-passport-solid:before {
  content: "\ed08";
}
.icon-pastafarianism-solid:before {
  content: "\ed09";
}
.icon-paste-solid:before {
  content: "\ed0a";
}
.icon-patreon:before {
  content: "\ed0b";
}
.icon-pause-circle:before {
  content: "\ed0c";
}
.icon-pause-circle-solid:before {
  content: "\ed0d";
}
.icon-pause-solid:before {
  content: "\ed0e";
}
.icon-paw-solid:before {
  content: "\ed0f";
}
.icon-paypal:before {
  content: "\ed10";
}
.icon-peace-solid:before {
  content: "\ed11";
}
.icon-pen-alt-solid:before {
  content: "\ed12";
}
.icon-pencil-alt-solid:before {
  content: "\ed13";
}
.icon-pencil-ruler-solid:before {
  content: "\ed14";
}
.icon-pen-fancy-solid:before {
  content: "\ed15";
}
.icon-pen-nib-solid:before {
  content: "\ed16";
}
.icon-penny-arcade:before {
  content: "\ed17";
}
.icon-pen-solid:before {
  content: "\ed18";
}
.icon-pen-square-solid:before {
  content: "\ed19";
}
.icon-people-carry-solid:before {
  content: "\ed1a";
}
.icon-pepper-hot-solid:before {
  content: "\ed1b";
}
.icon-percentage-solid:before {
  content: "\ed1c";
}
.icon-percent-solid:before {
  content: "\ed1d";
}
.icon-periscope:before {
  content: "\ed1e";
}
.icon-person-booth-solid:before {
  content: "\ed1f";
}
.icon-phabricator:before {
  content: "\ed20";
}
.icon-phoenix-framework:before {
  content: "\ed21";
}
.icon-phoenix-squadron:before {
  content: "\ed22";
}
.icon-phone-alt-solid:before {
  content: "\ed23";
}
.icon-phone-slash-solid:before {
  content: "\ed24";
}
.icon-phone-solid:before {
  content: "\ed25";
}
.icon-phone-square-alt-solid:before {
  content: "\ed26";
}
.icon-phone-square-solid:before {
  content: "\ed27";
}
.icon-phone-volume-solid:before {
  content: "\ed28";
}
.icon-photo-video-solid:before {
  content: "\ed29";
}
.icon-php:before {
  content: "\ed2a";
}
.icon-pied-piper:before {
  content: "\ed2b";
}
.icon-pied-piper-alt:before {
  content: "\ed2c";
}
.icon-pied-piper-hat:before {
  content: "\ed2d";
}
.icon-pied-piper-pp:before {
  content: "\ed2e";
}
.icon-piggy-bank-solid:before {
  content: "\ed2f";
}
.icon-pills-solid:before {
  content: "\ed30";
}
.icon-pinterest:before {
  content: "\ed31";
}
.icon-pinterest-p:before {
  content: "\ed32";
}
.icon-pinterest-square:before {
  content: "\ed33";
}
.icon-pizza-slice-solid:before {
  content: "\ed34";
}
.icon-place-of-worship-solid:before {
  content: "\ed35";
}
.icon-plane-arrival-solid:before {
  content: "\ed36";
}
.icon-plane-departure-solid:before {
  content: "\ed37";
}
.icon-plane-solid:before {
  content: "\ed38";
}
.icon-play-circle:before {
  content: "\ed39";
}
.icon-play-circle-solid:before {
  content: "\ed3a";
}
.icon-play-solid:before {
  content: "\ed3b";
}
.icon-playstation:before {
  content: "\ed3c";
}
.icon-plug-solid:before {
  content: "\ed3d";
}
.icon-plus-circle-solid:before {
  content: "\ed3e";
}
.icon-plus-solid:before {
  content: "\ed3f";
}
.icon-plus-square:before {
  content: "\ed40";
}
.icon-plus-square-solid:before {
  content: "\ed41";
}
.icon-podcast-solid:before {
  content: "\ed42";
}
.icon-poll-h-solid:before {
  content: "\ed43";
}
.icon-poll-solid:before {
  content: "\ed44";
}
.icon-poop-solid:before {
  content: "\ed45";
}
.icon-poo-solid:before {
  content: "\ed46";
}
.icon-poo-storm-solid:before {
  content: "\ed47";
}
.icon-portrait-solid:before {
  content: "\ed48";
}
.icon-pound-sign-solid:before {
  content: "\ed49";
}
.icon-power-off-solid:before {
  content: "\ed4a";
}
.icon-praying-hands-solid:before {
  content: "\ed4b";
}
.icon-pray-solid:before {
  content: "\ed4c";
}
.icon-prescription-bottle-alt-solid:before {
  content: "\ed4d";
}
.icon-prescription-bottle-solid:before {
  content: "\ed4e";
}
.icon-prescription-solid:before {
  content: "\ed4f";
}
.icon-print-solid:before {
  content: "\ed50";
}
.icon-procedures-solid:before {
  content: "\ed51";
}
.icon-product-hunt:before {
  content: "\ed52";
}
.icon-project-diagram-solid:before {
  content: "\ed53";
}
.icon-pushed:before {
  content: "\ed54";
}
.icon-puzzle-piece-solid:before {
  content: "\ed55";
}
.icon-python:before {
  content: "\ed56";
}
.icon-qq:before {
  content: "\ed57";
}
.icon-qrcode-solid:before {
  content: "\ed58";
}
.icon-question-circle:before {
  content: "\ed59";
}
.icon-question-circle-solid:before {
  content: "\ed5a";
}
.icon-question-solid:before {
  content: "\ed5b";
}
.icon-quidditch-solid:before {
  content: "\ed5c";
}
.icon-quinscape:before {
  content: "\ed5d";
}
.icon-quora:before {
  content: "\ed5e";
}
.icon-quote-left-solid:before {
  content: "\ed5f";
}
.icon-quote-right-solid:before {
  content: "\ed60";
}
.icon-quran-solid:before {
  content: "\ed61";
}
.icon-radiation-alt-solid:before {
  content: "\ed62";
}
.icon-radiation-solid:before {
  content: "\ed63";
}
.icon-rainbow-solid:before {
  content: "\ed64";
}
.icon-random-solid:before {
  content: "\ed65";
}
.icon-raspberry-pi:before {
  content: "\ed66";
}
.icon-ravelry:before {
  content: "\ed67";
}
.icon-react:before {
  content: "\ed68";
}
.icon-reacteurope:before {
  content: "\ed69";
}
.icon-readme:before {
  content: "\ed6a";
}
.icon-rebel:before {
  content: "\ed6b";
}
.icon-receipt-solid:before {
  content: "\ed6c";
}
.icon-record-vinyl-solid:before {
  content: "\ed6d";
}
.icon-recycle-solid:before {
  content: "\ed6e";
}
.icon-reddit:before {
  content: "\ed6f";
}
.icon-reddit-alien:before {
  content: "\ed70";
}
.icon-reddit-square:before {
  content: "\ed71";
}
.icon-redhat:before {
  content: "\ed72";
}
.icon-redo-alt-solid:before {
  content: "\ed73";
}
.icon-redo-solid:before {
  content: "\ed74";
}
.icon-red-river:before {
  content: "\ed75";
}
.icon-registered:before {
  content: "\ed76";
}
.icon-registered-solid:before {
  content: "\ed77";
}
.icon-remove-format-solid:before {
  content: "\ed78";
}
.icon-renren:before {
  content: "\ed79";
}
.icon-reply-all-solid:before {
  content: "\ed7a";
}
.icon-replyd:before {
  content: "\ed7b";
}
.icon-reply-solid:before {
  content: "\ed7c";
}
.icon-republican-solid:before {
  content: "\ed7d";
}
.icon-researchgate:before {
  content: "\ed7e";
}
.icon-resolving:before {
  content: "\ed7f";
}
.icon-restroom-solid:before {
  content: "\ed80";
}
.icon-retweet-solid:before {
  content: "\ed81";
}
.icon-rev:before {
  content: "\ed82";
}
.icon-ribbon-solid:before {
  content: "\ed83";
}
.icon-ring-solid:before {
  content: "\ed84";
}
.icon-road-solid:before {
  content: "\ed85";
}
.icon-robot-solid:before {
  content: "\ed86";
}
.icon-rocketchat:before {
  content: "\ed87";
}
.icon-rocket-solid:before {
  content: "\ed88";
}
.icon-rockrms:before {
  content: "\ed89";
}
.icon-route-solid:before {
  content: "\ed8a";
}
.icon-r-project:before {
  content: "\ed8b";
}
.icon-rss-solid:before {
  content: "\ed8c";
}
.icon-rss-square-solid:before {
  content: "\ed8d";
}
.icon-ruble-sign-solid:before {
  content: "\ed8e";
}
.icon-ruler-combined-solid:before {
  content: "\ed8f";
}
.icon-ruler-horizontal-solid:before {
  content: "\ed90";
}
.icon-ruler-solid:before {
  content: "\ed91";
}
.icon-ruler-vertical-solid:before {
  content: "\ed92";
}
.icon-running-solid:before {
  content: "\ed93";
}
.icon-rupee-sign-solid:before {
  content: "\ed94";
}
.icon-sad-cry:before {
  content: "\ed95";
}
.icon-sad-cry-solid:before {
  content: "\ed96";
}
.icon-sad-tear:before {
  content: "\ed97";
}
.icon-sad-tear-solid:before {
  content: "\ed98";
}
.icon-safari:before {
  content: "\ed99";
}
.icon-salesforce:before {
  content: "\ed9a";
}
.icon-sass:before {
  content: "\ed9b";
}
.icon-satellite-dish-solid:before {
  content: "\ed9c";
}
.icon-satellite-solid:before {
  content: "\ed9d";
}
.icon-save:before {
  content: "\ed9e";
}
.icon-save-solid:before {
  content: "\ed9f";
}
.icon-schlix:before {
  content: "\eda0";
}
.icon-school-solid:before {
  content: "\eda1";
}
.icon-screwdriver-solid:before {
  content: "\eda2";
}
.icon-scribd:before {
  content: "\eda3";
}
.icon-scroll-solid:before {
  content: "\eda4";
}
.icon-sd-card-solid:before {
  content: "\eda5";
}
.icon-search-dollar-solid:before {
  content: "\eda6";
}
.icon-searchengin:before {
  content: "\eda7";
}
.icon-search-location-solid:before {
  content: "\eda8";
}
.icon-search-minus-solid:before {
  content: "\eda9";
}
.icon-search-plus-solid:before {
  content: "\edaa";
}
.icon-search-solid:before {
  content: "\edab";
}
.icon-seedling-solid:before {
  content: "\edac";
}
.icon-sellcast:before {
  content: "\edad";
}
.icon-sellsy:before {
  content: "\edae";
}
.icon-server-solid:before {
  content: "\edaf";
}
.icon-servicestack:before {
  content: "\edb0";
}
.icon-shapes-solid:before {
  content: "\edb1";
}
.icon-share-alt-solid:before {
  content: "\edb2";
}
.icon-share-alt-square-solid:before {
  content: "\edb3";
}
.icon-share-solid:before {
  content: "\edb4";
}
.icon-share-square:before {
  content: "\edb5";
}
.icon-share-square-solid:before {
  content: "\edb6";
}
.icon-shekel-sign-solid:before {
  content: "\edb7";
}
.icon-shield-alt-solid:before {
  content: "\edb8";
}
.icon-shipping-fast-solid:before {
  content: "\edb9";
}
.icon-ship-solid:before {
  content: "\edba";
}
.icon-shirtsinbulk:before {
  content: "\edbb";
}
.icon-shoe-prints-solid:before {
  content: "\edbc";
}
.icon-shopping-bag-solid:before {
  content: "\edbd";
}
.icon-shopping-basket-solid:before {
  content: "\edbe";
}
.icon-shopping-cart-solid:before {
  content: "\edbf";
}
.icon-shopware:before {
  content: "\edc0";
}
.icon-shower-solid:before {
  content: "\edc1";
}
.icon-shuttle-van-solid:before {
  content: "\edc2";
}
.icon-signal-solid:before {
  content: "\edc3";
}
.icon-signature-solid:before {
  content: "\edc4";
}
.icon-sign-in-alt-solid:before {
  content: "\edc5";
}
.icon-sign-language-solid:before {
  content: "\edc6";
}
.icon-sign-out-alt-solid:before {
  content: "\edc7";
}
.icon-sign-solid:before {
  content: "\edc8";
}
.icon-sim-card-solid:before {
  content: "\edc9";
}
.icon-simplybuilt:before {
  content: "\edca";
}
.icon-sistrix:before {
  content: "\edcb";
}
.icon-sitemap-solid:before {
  content: "\edcc";
}
.icon-sith:before {
  content: "\edcd";
}
.icon-skating-solid:before {
  content: "\edce";
}
.icon-sketch:before {
  content: "\edcf";
}
.icon-skiing-nordic-solid:before {
  content: "\edd0";
}
.icon-skiing-solid:before {
  content: "\edd1";
}
.icon-skull-crossbones-solid:before {
  content: "\edd2";
}
.icon-skull-solid:before {
  content: "\edd3";
}
.icon-skyatlas:before {
  content: "\edd4";
}
.icon-skype:before {
  content: "\edd5";
}
.icon-slack:before {
  content: "\edd6";
}
.icon-slack-hash:before {
  content: "\edd7";
}
.icon-slash-solid:before {
  content: "\edd8";
}
.icon-sleigh-solid:before {
  content: "\edd9";
}
.icon-sliders-h-solid:before {
  content: "\edda";
}
.icon-slideshare:before {
  content: "\eddb";
}
.icon-smile:before {
  content: "\eddc";
}
.icon-smile-beam:before {
  content: "\eddd";
}
.icon-smile-beam-solid:before {
  content: "\edde";
}
.icon-smile-solid:before {
  content: "\eddf";
}
.icon-smile-wink:before {
  content: "\ede0";
}
.icon-smile-wink-solid:before {
  content: "\ede1";
}
.icon-smog-solid:before {
  content: "\ede2";
}
.icon-smoking-ban-solid:before {
  content: "\ede3";
}
.icon-smoking-solid:before {
  content: "\ede4";
}
.icon-sms-solid:before {
  content: "\ede5";
}
.icon-snapchat:before {
  content: "\ede6";
}
.icon-snapchat-ghost:before {
  content: "\ede7";
}
.icon-snapchat-square:before {
  content: "\ede8";
}
.icon-snowboarding-solid:before {
  content: "\ede9";
}
.icon-snowflake:before {
  content: "\edea";
}
.icon-snowflake-solid:before {
  content: "\edeb";
}
.icon-snowman-solid:before {
  content: "\edec";
}
.icon-snowplow-solid:before {
  content: "\eded";
}
.icon-socks-solid:before {
  content: "\edee";
}
.icon-solar-panel-solid:before {
  content: "\edef";
}
.icon-sort-alpha-down-alt-solid:before {
  content: "\edf0";
}
.icon-sort-alpha-down-solid:before {
  content: "\edf1";
}
.icon-sort-alpha-up-alt-solid:before {
  content: "\edf2";
}
.icon-sort-alpha-up-solid:before {
  content: "\edf3";
}
.icon-sort-amount-down-alt-solid:before {
  content: "\edf4";
}
.icon-sort-amount-down-solid:before {
  content: "\edf5";
}
.icon-sort-amount-up-alt-solid:before {
  content: "\edf6";
}
.icon-sort-amount-up-solid:before {
  content: "\edf7";
}
.icon-sort-down-solid:before {
  content: "\edf8";
}
.icon-sort-numeric-down-alt-solid:before {
  content: "\edf9";
}
.icon-sort-numeric-down-solid:before {
  content: "\edfa";
}
.icon-sort-numeric-up-alt-solid:before {
  content: "\edfb";
}
.icon-sort-numeric-up-solid:before {
  content: "\edfc";
}
.icon-sort-solid:before {
  content: "\edfd";
}
.icon-sort-up-solid:before {
  content: "\edfe";
}
.icon-soundcloud:before {
  content: "\edff";
}
.icon-sourcetree:before {
  content: "\ee00";
}
.icon-space-shuttle-solid:before {
  content: "\ee01";
}
.icon-spa-solid:before {
  content: "\ee02";
}
.icon-speakap:before {
  content: "\ee03";
}
.icon-speaker-deck:before {
  content: "\ee04";
}
.icon-spell-check-solid:before {
  content: "\ee05";
}
.icon-spider-solid:before {
  content: "\ee06";
}
.icon-spinner-solid:before {
  content: "\ee07";
}
.icon-splotch-solid:before {
  content: "\ee08";
}
.icon-spotify:before {
  content: "\ee09";
}
.icon-spray-can-solid:before {
  content: "\ee0a";
}
.icon-square:before {
  content: "\ee0b";
}
.icon-square-full-solid:before {
  content: "\ee0c";
}
.icon-square-root-alt-solid:before {
  content: "\ee0d";
}
.icon-square-solid:before {
  content: "\ee0e";
}
.icon-squarespace:before {
  content: "\ee0f";
}
.icon-stack-exchange:before {
  content: "\ee10";
}
.icon-stack-overflow:before {
  content: "\ee11";
}
.icon-stackpath:before {
  content: "\ee12";
}
.icon-stamp-solid:before {
  content: "\ee13";
}
.icon-star:before {
  content: "\ee14";
}
.icon-star-and-crescent-solid:before {
  content: "\ee15";
}
.icon-star-half:before {
  content: "\ee16";
}
.icon-star-half-alt-solid:before {
  content: "\ee17";
}
.icon-star-half-solid:before {
  content: "\ee18";
}
.icon-star-of-david-solid:before {
  content: "\ee19";
}
.icon-star-of-life-solid:before {
  content: "\ee1a";
}
.icon-star-solid:before {
  content: "\ee1b";
}
.icon-staylinked:before {
  content: "\ee1c";
}
.icon-steam:before {
  content: "\ee1d";
}
.icon-steam-square:before {
  content: "\ee1e";
}
.icon-steam-symbol:before {
  content: "\ee1f";
}
.icon-step-backward-solid:before {
  content: "\ee20";
}
.icon-step-forward-solid:before {
  content: "\ee21";
}
.icon-stethoscope-solid:before {
  content: "\ee22";
}
.icon-sticker-mule:before {
  content: "\ee23";
}
.icon-sticky-note:before {
  content: "\ee24";
}
.icon-sticky-note-solid:before {
  content: "\ee25";
}
.icon-stop-circle:before {
  content: "\ee26";
}
.icon-stop-circle-solid:before {
  content: "\ee27";
}
.icon-stop-solid:before {
  content: "\ee28";
}
.icon-stopwatch-solid:before {
  content: "\ee29";
}
.icon-store-alt-solid:before {
  content: "\ee2a";
}
.icon-store-solid:before {
  content: "\ee2b";
}
.icon-strava:before {
  content: "\ee2c";
}
.icon-stream-solid:before {
  content: "\ee2d";
}
.icon-street-view-solid:before {
  content: "\ee2e";
}
.icon-strikethrough-solid:before {
  content: "\ee2f";
}
.icon-stripe:before {
  content: "\ee30";
}
.icon-stripe-s:before {
  content: "\ee31";
}
.icon-stroopwafel-solid:before {
  content: "\ee32";
}
.icon-studiovinari:before {
  content: "\ee33";
}
.icon-stumbleupon:before {
  content: "\ee34";
}
.icon-stumbleupon-circle:before {
  content: "\ee35";
}
.icon-subscript-solid:before {
  content: "\ee36";
}
.icon-subway-solid:before {
  content: "\ee37";
}
.icon-suitcase-rolling-solid:before {
  content: "\ee38";
}
.icon-suitcase-solid:before {
  content: "\ee39";
}
.icon-sun:before {
  content: "\ee3a";
}
.icon-sun-solid:before {
  content: "\ee3b";
}
.icon-superpowers:before {
  content: "\ee3c";
}
.icon-superscript-solid:before {
  content: "\ee3d";
}
.icon-supple:before {
  content: "\ee3e";
}
.icon-surprise:before {
  content: "\ee3f";
}
.icon-surprise-solid:before {
  content: "\ee40";
}
.icon-suse:before {
  content: "\ee41";
}
.icon-swatchbook-solid:before {
  content: "\ee42";
}
.icon-swift:before {
  content: "\ee43";
}
.icon-swimmer-solid:before {
  content: "\ee44";
}
.icon-swimming-pool-solid:before {
  content: "\ee45";
}
.icon-symfony:before {
  content: "\ee46";
}
.icon-synagogue-solid:before {
  content: "\ee47";
}
.icon-sync-alt-solid:before {
  content: "\ee48";
}
.icon-sync-solid:before {
  content: "\ee49";
}
.icon-syringe-solid:before {
  content: "\ee4a";
}
.icon-table-solid:before {
  content: "\ee4b";
}
.icon-tablet-alt-solid:before {
  content: "\ee4c";
}
.icon-table-tennis-solid:before {
  content: "\ee4d";
}
.icon-tablet-solid:before {
  content: "\ee4e";
}
.icon-tablets-solid:before {
  content: "\ee4f";
}
.icon-tachometer-alt-solid:before {
  content: "\ee50";
}
.icon-tag-solid:before {
  content: "\ee51";
}
.icon-tags-solid:before {
  content: "\ee52";
}
.icon-tape-solid:before {
  content: "\ee53";
}
.icon-tasks-solid:before {
  content: "\ee54";
}
.icon-taxi-solid:before {
  content: "\ee55";
}
.icon-teamspeak:before {
  content: "\ee56";
}
.icon-teeth-open-solid:before {
  content: "\ee57";
}
.icon-teeth-solid:before {
  content: "\ee58";
}
.icon-telegram:before {
  content: "\ee59";
}
.icon-telegram-plane:before {
  content: "\ee5a";
}
.icon-temperature-high-solid:before {
  content: "\ee5b";
}
.icon-temperature-low-solid:before {
  content: "\ee5c";
}
.icon-tencent-weibo:before {
  content: "\ee5d";
}
.icon-tenge-solid:before {
  content: "\ee5e";
}
.icon-terminal-solid:before {
  content: "\ee5f";
}
.icon-text-height-solid:before {
  content: "\ee60";
}
.icon-text-width-solid:before {
  content: "\ee61";
}
.icon-theater-masks-solid:before {
  content: "\ee62";
}
.icon-themeco:before {
  content: "\ee63";
}
.icon-themeisle:before {
  content: "\ee64";
}
.icon-the-red-yeti:before {
  content: "\ee65";
}
.icon-thermometer-empty-solid:before {
  content: "\ee66";
}
.icon-thermometer-full-solid:before {
  content: "\ee67";
}
.icon-thermometer-half-solid:before {
  content: "\ee68";
}
.icon-thermometer-quarter-solid:before {
  content: "\ee69";
}
.icon-thermometer-solid:before {
  content: "\ee6a";
}
.icon-thermometer-three-quarters-solid:before {
  content: "\ee6b";
}
.icon-think-peaks:before {
  content: "\ee6c";
}
.icon-th-large-solid:before {
  content: "\ee6d";
}
.icon-th-list-solid:before {
  content: "\ee6e";
}
.icon-th-solid:before {
  content: "\ee6f";
}
.icon-thumbs-down:before {
  content: "\ee70";
}
.icon-thumbs-down-solid:before {
  content: "\ee71";
}
.icon-thumbs-up:before {
  content: "\ee72";
}
.icon-thumbs-up-solid:before {
  content: "\ee73";
}
.icon-thumbtack-solid:before {
  content: "\ee74";
}
.icon-ticket-alt-solid:before {
  content: "\ee75";
}
.icon-times-circle:before {
  content: "\ee76";
}
.icon-times-circle-solid:before {
  content: "\ee77";
}
.icon-times-solid:before {
  content: "\ee78";
}
.icon-tint-slash-solid:before {
  content: "\ee79";
}
.icon-tint-solid:before {
  content: "\ee7a";
}
.icon-tired:before {
  content: "\ee7b";
}
.icon-tired-solid:before {
  content: "\ee7c";
}
.icon-toggle-off-solid:before {
  content: "\ee7d";
}
.icon-toggle-on-solid:before {
  content: "\ee7e";
}
.icon-toilet-paper-solid:before {
  content: "\ee7f";
}
.icon-toilet-solid:before {
  content: "\ee80";
}
.icon-toolbox-solid:before {
  content: "\ee81";
}
.icon-tools-solid:before {
  content: "\ee82";
}
.icon-tooth-solid:before {
  content: "\ee83";
}
.icon-torah-solid:before {
  content: "\ee84";
}
.icon-torii-gate-solid:before {
  content: "\ee85";
}
.icon-tractor-solid:before {
  content: "\ee86";
}
.icon-trade-federation:before {
  content: "\ee87";
}
.icon-trademark-solid:before {
  content: "\ee88";
}
.icon-traffic-light-solid:before {
  content: "\ee89";
}
.icon-train-solid:before {
  content: "\ee8a";
}
.icon-tram-solid:before {
  content: "\ee8b";
}
.icon-transgender-alt-solid:before {
  content: "\ee8c";
}
.icon-transgender-solid:before {
  content: "\ee8d";
}
.icon-trash-alt:before {
  content: "\ee8e";
}
.icon-trash-alt-solid:before {
  content: "\ee8f";
}
.icon-trash-restore-alt-solid:before {
  content: "\ee90";
}
.icon-trash-restore-solid:before {
  content: "\ee91";
}
.icon-trash-solid:before {
  content: "\ee92";
}
.icon-tree-solid:before {
  content: "\ee93";
}
.icon-trello:before {
  content: "\ee94";
}
.icon-tripadvisor:before {
  content: "\ee95";
}
.icon-trophy-solid:before {
  content: "\ee96";
}
.icon-truck-loading-solid:before {
  content: "\ee97";
}
.icon-truck-monster-solid:before {
  content: "\ee98";
}
.icon-truck-moving-solid:before {
  content: "\ee99";
}
.icon-truck-pickup-solid:before {
  content: "\ee9a";
}
.icon-truck-solid:before {
  content: "\ee9b";
}
.icon-tshirt-solid:before {
  content: "\ee9c";
}
.icon-tty-solid:before {
  content: "\ee9d";
}
.icon-tumblr:before {
  content: "\ee9e";
}
.icon-tumblr-square:before {
  content: "\ee9f";
}
.icon-tv-solid:before {
  content: "\eea0";
}
.icon-twitch:before {
  content: "\eea1";
}
.icon-twitter:before {
  content: "\eea2";
}
.icon-twitter-square:before {
  content: "\eea3";
}
.icon-typo3:before {
  content: "\eea4";
}
.icon-uber:before {
  content: "\eea5";
}
.icon-ubuntu:before {
  content: "\eea6";
}
.icon-uikit:before {
  content: "\eea7";
}
.icon-umbraco:before {
  content: "\eea8";
}
.icon-umbrella-beach-solid:before {
  content: "\eea9";
}
.icon-umbrella-solid:before {
  content: "\eeaa";
}
.icon-underline-solid:before {
  content: "\eeab";
}
.icon-undo-alt-solid:before {
  content: "\eeac";
}
.icon-undo-solid:before {
  content: "\eead";
}
.icon-uniregistry:before {
  content: "\eeae";
}
.icon-universal-access-solid:before {
  content: "\eeaf";
}
.icon-university-solid:before {
  content: "\eeb0";
}
.icon-unlink-solid:before {
  content: "\eeb1";
}
.icon-unlock-alt-solid:before {
  content: "\eeb2";
}
.icon-unlock-solid:before {
  content: "\eeb3";
}
.icon-untappd:before {
  content: "\eeb4";
}
.icon-upload-solid:before {
  content: "\eeb5";
}
.icon-ups:before {
  content: "\eeb6";
}
.icon-usb:before {
  content: "\eeb7";
}
.icon-user:before {
  content: "\eeb8";
}
.icon-user-alt-slash-solid:before {
  content: "\eeb9";
}
.icon-user-alt-solid:before {
  content: "\eeba";
}
.icon-user-astronaut-solid:before {
  content: "\eebb";
}
.icon-user-check-solid:before {
  content: "\eebc";
}
.icon-user-circle:before {
  content: "\eebd";
}
.icon-user-circle-solid:before {
  content: "\eebe";
}
.icon-user-clock-solid:before {
  content: "\eebf";
}
.icon-user-cog-solid:before {
  content: "\eec0";
}
.icon-user-edit-solid:before {
  content: "\eec1";
}
.icon-user-friends-solid:before {
  content: "\eec2";
}
.icon-user-graduate-solid:before {
  content: "\eec3";
}
.icon-user-injured-solid:before {
  content: "\eec4";
}
.icon-user-lock-solid:before {
  content: "\eec5";
}
.icon-user-md-solid:before {
  content: "\eec6";
}
.icon-user-minus-solid:before {
  content: "\eec7";
}
.icon-user-ninja-solid:before {
  content: "\eec8";
}
.icon-user-nurse-solid:before {
  content: "\eec9";
}
.icon-user-plus-solid:before {
  content: "\eeca";
}
.icon-users-cog-solid:before {
  content: "\eecb";
}
.icon-user-secret-solid:before {
  content: "\eecc";
}
.icon-user-shield-solid:before {
  content: "\eecd";
}
.icon-user-slash-solid:before {
  content: "\eece";
}
.icon-user-solid:before {
  content: "\eecf";
}
.icon-users-solid:before {
  content: "\eed0";
}
.icon-user-tag-solid:before {
  content: "\eed1";
}
.icon-user-tie-solid:before {
  content: "\eed2";
}
.icon-user-times-solid:before {
  content: "\eed3";
}
.icon-usps:before {
  content: "\eed4";
}
.icon-ussunnah:before {
  content: "\eed5";
}
.icon-utensil-spoon-solid:before {
  content: "\eed6";
}
.icon-utensils-solid:before {
  content: "\eed7";
}
.icon-vaadin:before {
  content: "\eed8";
}
.icon-vector-square-solid:before {
  content: "\eed9";
}
.icon-venus-double-solid:before {
  content: "\eeda";
}
.icon-venus-mars-solid:before {
  content: "\eedb";
}
.icon-venus-solid:before {
  content: "\eedc";
}
.icon-viacoin:before {
  content: "\eedd";
}
.icon-viadeo:before {
  content: "\eede";
}
.icon-viadeo-square:before {
  content: "\eedf";
}
.icon-vial-solid:before {
  content: "\eee0";
}
.icon-vials-solid:before {
  content: "\eee1";
}
.icon-viber:before {
  content: "\eee2";
}
.icon-video-slash-solid:before {
  content: "\eee3";
}
.icon-video-solid:before {
  content: "\eee4";
}
.icon-vihara-solid:before {
  content: "\eee5";
}
.icon-vimeo:before {
  content: "\eee6";
}
.icon-vimeo-square:before {
  content: "\eee7";
}
.icon-vimeo-v:before {
  content: "\eee8";
}
.icon-vine:before {
  content: "\eee9";
}
.icon-vk:before {
  content: "\eeea";
}
.icon-vnv:before {
  content: "\eeeb";
}
.icon-voicemail-solid:before {
  content: "\eeec";
}
.icon-volleyball-ball-solid:before {
  content: "\eeed";
}
.icon-volume-down-solid:before {
  content: "\eeee";
}
.icon-volume-mute-solid:before {
  content: "\eeef";
}
.icon-volume-off-solid:before {
  content: "\eef0";
}
.icon-volume-up-solid:before {
  content: "\eef1";
}
.icon-vote-yea-solid:before {
  content: "\eef2";
}
.icon-vr-cardboard-solid:before {
  content: "\eef3";
}
.icon-vuejs:before {
  content: "\eef4";
}
.icon-walking-solid:before {
  content: "\eef5";
}
.icon-wallet-solid:before {
  content: "\eef6";
}
.icon-warehouse-solid:before {
  content: "\eef7";
}
.icon-water-solid:before {
  content: "\eef8";
}
.icon-wave-square-solid:before {
  content: "\eef9";
}
.icon-waze:before {
  content: "\eefa";
}
.icon-weebly:before {
  content: "\eefb";
}
.icon-weibo:before {
  content: "\eefc";
}
.icon-weight-hanging-solid:before {
  content: "\eefd";
}
.icon-weight-solid:before {
  content: "\eefe";
}
.icon-weixin:before {
  content: "\eeff";
}
.icon-whatsapp:before {
  content: "\ef00";
}
.icon-whatsapp-square:before {
  content: "\ef01";
}
.icon-wheelchair-solid:before {
  content: "\ef02";
}
.icon-whmcs:before {
  content: "\ef03";
}
.icon-wifi-solid:before {
  content: "\ef04";
}
.icon-wikipedia-w:before {
  content: "\ef05";
}
.icon-window-close:before {
  content: "\ef06";
}
.icon-window-close-solid:before {
  content: "\ef07";
}
.icon-window-maximize:before {
  content: "\ef08";
}
.icon-window-maximize-solid:before {
  content: "\ef09";
}
.icon-window-minimize:before {
  content: "\ef0a";
}
.icon-window-minimize-solid:before {
  content: "\ef0b";
}
.icon-window-restore:before {
  content: "\ef0c";
}
.icon-window-restore-solid:before {
  content: "\ef0d";
}
.icon-windows:before {
  content: "\ef0e";
}
.icon-wind-solid:before {
  content: "\ef0f";
}
.icon-wine-bottle-solid:before {
  content: "\ef10";
}
.icon-wine-glass-alt-solid:before {
  content: "\ef11";
}
.icon-wine-glass-solid:before {
  content: "\ef12";
}
.icon-wix:before {
  content: "\ef13";
}
.icon-wizards-of-the-coast:before {
  content: "\ef14";
}
.icon-wolf-pack-battalion:before {
  content: "\ef15";
}
.icon-won-sign-solid:before {
  content: "\ef16";
}
.icon-wordpress:before {
  content: "\ef17";
}
.icon-wordpress-simple:before {
  content: "\ef18";
}
.icon-wpbeginner:before {
  content: "\ef19";
}
.icon-wpexplorer:before {
  content: "\ef1a";
}
.icon-wpforms:before {
  content: "\ef1b";
}
.icon-wpressr:before {
  content: "\ef1c";
}
.icon-wrench-solid:before {
  content: "\ef1d";
}
.icon-xbox:before {
  content: "\ef1e";
}
.icon-xing:before {
  content: "\ef1f";
}
.icon-xing-square:before {
  content: "\ef20";
}
.icon-x-ray-solid:before {
  content: "\ef21";
}
.icon-yahoo:before {
  content: "\ef22";
}
.icon-yammer:before {
  content: "\ef23";
}
.icon-yandex:before {
  content: "\ef24";
}
.icon-yandex-international:before {
  content: "\ef25";
}
.icon-yarn:before {
  content: "\ef26";
}
.icon-y-combinator:before {
  content: "\ef27";
}
.icon-yelp:before {
  content: "\ef28";
}
.icon-yen-sign-solid:before {
  content: "\ef29";
}
.icon-yin-yang-solid:before {
  content: "\ef2a";
}
.icon-yoast:before {
  content: "\ef2b";
}
.icon-youtube:before {
  content: "\ef2c";
}
.icon-youtube-square:before {
  content: "\ef2d";
}
.icon-zhihu:before {
  content: "\ef2e";
}
.icon-bells:before {
  content: "\e907";
}
.icon-calender:before {
  content: "\e909";
}
.icon-calender-list:before {
  content: "\e90d";
}
.icon-cart:before {
  content: "\e90f";
  color: #4c4372;
}
.icon-chart:before {
  content: "\e911";
}
.icon-chart-circle-border:before {
  content: "\e913";
}
.icon-circle:before {
  content: "\e917";
}
.icon-circlegroup:before {
  content: "\e919";
}
.icon-clipboard:before {
  content: "\e91b";
}
.icon-dots:before {
  content: "\e91d";
}
.icon-eye1:before {
  content: "\e933";
}
.icon-filter .path1:before {
  content: "\e935";
  color: rgba(0, 0, 0, 0.3);
}
.icon-filter .path2:before {
  content: "\f06c";
  margin-left: -1.0361328125em;
  color: rgb(255, 255, 255);
}
.icon-folder:before {
  content: "\e931";
}
.icon-folder-circle:before {
  content: "\e92f";
}
.icon-gender:before {
  content: "\e92d";
}
.icon-grid:before {
  content: "\e92b";
}
.icon-Headphones-support:before {
  content: "\e929";
}
.icon-help:before {
  content: "\e927";
  color: #23a8b4;
}
.icon-language:before {
  content: "\e925";
}
.icon-light:before {
  content: "\e921";
}
.icon-location:before {
  content: "\e922";
}
.icon-lock:before {
  content: "\e923";
}
.icon-Maximize:before {
  content: "\e937";
}
.icon-medical-sign:before {
  content: "\e939";
}
.icon-menu:before {
  content: "\e93b";
  color: #aaa;
}
.icon-pages:before {
  content: "\e93d";
}
.icon-patient-search:before {
  content: "\e93f";
}
.icon-patient:before {
  content: "\e941";
  color: #4c4372;
}
.icon-pdf .path1:before {
  content: "\e943";
  color: rgb(239, 242, 243);
}
.icon-pdf .path2:before {
  content: "\f06d";
  margin-left: -0.8203125em;
  color: rgb(218, 222, 222);
}
.icon-pdf .path3:before {
  content: "\f06e";
  margin-left: -0.8203125em;
  color: rgb(242, 120, 107);
}
.icon-pdf .path4:before {
  content: "\f06f";
  margin-left: -0.8203125em;
  color: rgb(239, 242, 243);
}
.icon-people:before {
  content: "\e945";
}
.icon-phone:before {
  content: "\e947";
}
.icon-search:before {
  content: "\e949";
}
.icon-search-border:before {
  content: "\e94f";
}
.icon-setting:before {
  content: "\e94b";
}
.icon-stars:before {
  content: "\e94d";
}
.icon-taskboard:before {
  content: "\e951";
}
.icon-tasks:before {
  content: "\e953";
}
.icon-cog:before {
  content: "\ef2f";
}
.icon-cogs:before {
  content: "\ef30";
}
.icon-contrast:before {
  content: "\ef31";
}
.icon-exit:before {
  content: "\f01c";
}
