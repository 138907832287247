html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}
p {
  font-family: 'Inter';
  letter-spacing: 0.16px;
  font-size: 15px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.overlay-container {
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fs {
  font-size: medium;
  letter-spacing: 1px;
}
.font-style {
  font-family: 'normal normal normal 16px/19px Barlow';
  font: normal normal normal 16px/19px Barlow;
  letter-spacing: 0.16px;
}
.sm__50{
  scroll-margin: 100px;
  scroll-behavior: smooth;
}
.sm__200{
  scroll-margin: 200px;
  scroll-behavior: smooth;
}
.sm__200{
  scroll-margin:100px;
}
.font-heading {
  font-family: 'normal normal normal 37px/44px Barlow';
  font: normal normal normal 20px/20px Barlow;
  letter-spacing: 7.4px;
}
.position {
  text-align: center;
  margin-left: 1%;
  top: 51%;
}
ol::content {
  list-style-type: disc
}
.pointer {
  cursor: pointer;
}
.input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}
.input-2 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  color: #000;
  font-weight: 500;
  line-height: 1.5;
  height: calc(2.6em + .75rem + 5px);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}
.input-2:focus {
  /* border-top: none;
  border-left: none;
  border-right: none; */
  outline: none;
}
.react-scroll-to-bottom--css-zfxqw-1n7m0yu {
  height: 300px;
  overflow-x: overlay;
  width: 100%;
}
.bg-card {
  background-color: #D7E1D8;
}
.text-size {
  font-size: 14px;
    padding-bottom: 3px;
    font-family: 'Inter';
}
.navlink:hover {
   background: #5E81F4;
}
.text-heading {
  background:#5E81F4!important;
  color: white;
}
.card-bg {
  background-color: #D7E1D8;
}
.bcg-card {
  background-color: #E4D6D6;
}
.mrginTop {
  margin-top: 16%;
}
textarea {
  border: none;
  width: 100%;
  line-height: 20px;
  resize: none;
  outline: none;
}
.fixing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt {
  position: absolute;
  margin-top: -16%;
  margin-left: 32%;
}
.mt2 {
  position: absolute;
  margin-top: -27%;
  margin-left: 27%;
}
.mt2_1 {
  position: absolute;
  right: 5px;
  top: 5px;
  float: right;
}
.mt3 {
  position: absolute;
  margin-top: -15%;
  margin-left: 27%;
}
.mt4 {
  position: absolute;
  margin-top: -12%;
  margin-left: 27%;
}
.sch {
  margin-top: 3%;
  margin-left: 5%;
}
.hw {
  width: 100%;
  height: 100%;
}
.mr_10 {
  margin: 5px;
  padding: 2px;
  gap: 10px;
  align-items: center;
}
.mr_25 {
  margin-left: 30px;
  align-items: center;
  color: "#ff0000";
}
.errorcolor {
  color: #ff0000;
}
.success {
  background-color: green !important;
  color: #fff !important;
}
.fail {
  background-color: red !important;
  color: #fff !important;
}
.zindex {
  z-index: 1999;
}
.zindexfull {

  z-index:999999;
}

.zindexfull1 thead {
  z-index: 1000; 
  position: relative; 
}

.pyscroll {
  height: 220px;
  overflow-y: scroll;
}
.scroll-harizantal{
  overflow-x: scroll;
  padding-top: 1%;
  margin-bottom: 2%;
  height:auto;
}
.scroll-vertical{
  overflow-y: scroll;
  margin-left:"10%";
}
.img-h {
  height: 420px;
}
input {
  border: black solid 2px;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.uppercase {
  text-transform: capitalize;
}
.label1 {
  margin-left: 2.1%;
  margin-top: -0.3%;
  font-size: large;
}
.prod-label{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
display: flex;
color: #232424;
margin-left: 5px;
}
.prod-label1{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #232424;
  margin-left: 5px;
  }
.label2 {
  margin-left: 1.5%;
  margin-top: 0.5%;
  font-size: large;
}
.w_20 {
  width: 20%;
}
#button-position {
  position: relative;
  left: 28px;
  bottom: 20px;
  opacity: 1;
  width: 40px;
  height: 27px;
}
.scroll {
  overflow-y: scroll;
  height: 350px;
  overflow-x: hidden;
}
.dob {
  margin-top: -3.5%;
  margin-left: 4.5%;
  font-size: smaller;
  color: gray;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #1a4b7e;
}
.bg-btn-primary {
  background: #52AFFF;
  padding: 3%;
  border: #52AFFF;
  border-radius: 5% 0% 0% 5%;
}
.bg-btn-primary-2 {
  background: #4e66b0;
  border: #4e66b0;
}
.bg-btn-pink-2 {
  background: #d240a4;
  border: #d240a4;
}
.bg-btn-purple-2 {
  background:purple;
  border: purple;
}
.bg-btn-yellow-2 {
  background:rgb(213, 213, 110);
  border: yellow;
}
.bg-btn-green-2 {
  background:green;
  border: green;
}
.bg-btn-white {
  background: white;
}
.bg-btn-white-2 {
  background: white;
}
.pointer {
  cursor: pointer;
}
.lineHeight {
  line-height: 2.5;
  margin-top: 2px;
  margin-bottom: 2px;
}
.bgblue {
  background-color: #d3e2e1;
}
.bgwhite{
  background-color: white;
}
.Datefontsize {
  font-size: 70px;
}
@import '/node_modules/react-datepicker/src/stylesheets/datepicker.scss';
.react-datepicker__input-container input{
 font-size: 14px;
 padding: 10px;
 position:initial;
 z-index: 1;
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
line-height: 20px;
height: 44px;
display: flex;
color: #232424;
}
@import '/node_modules/react-select-search/style.css';
.select-search { 
  width: 100% !important;
  position: relative;
  box-sizing: border-box;
  font-family:'Inter', sans-serif;
  border: #232424;
}
.select-search:not(.is-disabled) .select-search__input {
  height: 44px !important;
  font-size: 14px;
  font-weight: 600;
  width:100% !important;
  font-family:'Inter', sans-serif;
  border: #232424 !important;
  color: black;
}
.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color:#232424;
  font-family:'Inter', sans-serif;
}
.select-search__select {
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  font-family:'Barlow', sans-serif;
}
@media (max-width: 768px){.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
  padding-left: 5%;
}
.title-main{
  display: flex;  
  padding-left: 0%;
  margin-right: 0%; 
}
}
.position{
  position:absolute;
  top: 8%;
  left:20%;
  border-radius: 10%;
  cursor: pointer;
}
.position-two{
  position:absolute;
  top: 9%;
  left:-1%;
  border-radius: 10%;
  cursor: pointer;
}
.condition-scroll{
  height: 300px;
  overflow-y: scroll;
}
.absolute{
  position:absolute;
  top:12%;
  left:10%
}
.pointer{
  cursor: pointer;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 300px;
  padding: 12px 16px;
  z-index: 2;
  margin-top:0.5rem;
  background-color: white;
  z-index: 2;
  margin-top:0.5rem;
  background-color: white;
  box-shadow: 0px 1px 2px #D3E0E8;
}
.physician{
  position: absolute;
  right: 1.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
 .sm_50{
  scroll-margin: 50px;
  scroll-behavior: smooth;
 }
 .patient-gender{
  position: absolute;
  top: 75px;
  margin-left: 100px;
  width: 28px;
  height: 30px;
  padding: auto;
  border: 2px #79A3E0;
  background-color:white;
  border-radius: 100px;
 }
 .icon{
  padding: 5px 0px;
 }
 .cd{
  height: 300px;
  background-color: #fff
 }
 .cd:hover{
  background-color: rgb(241, 234, 234);
 }
  .theme-text-color{
    color: #000;
    font-size: 1.5rem;
    font-weight: 600;
  }
.text-light{
  color: #747576;
  width: 28px;
  height: 16px;
}
.profile-height{
  min-height: 50vh;
}
.btn-tabs{
  color: #1E1E1E;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius:5px;
  box-sizing: border-box;
   border:#37373a 1px solid
}
.btn-tabs1{
 background-color: #6c757d; 
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border-radius:5px;
  box-sizing: border-box;
   border:#6c757d 1px solid
}
.tab-scrolbar{
  overflow-x: auto;
}
.btn-plus{
  background-color: green;
  color: white;
  border: green;
}
.bg-questionnair{
  background-color: rgb(207 212 245);
}
.modal-title1{
    margin-bottom: 0;
    line-height: 1.5;
    color: #FFFFFF;
    background-color: #232424;
}
.patient-modal-title{
  margin-bottom: 0;
  line-height: 1.5;
  color: #FFFFFF;
  background-color: #232424;
  height: 50px;
}
.button-position1 {
  position: relative;
  left: 370px;
  bottom: 9px;
  background-color: #232424;
  opacity: 1;
  width: 40px;
  height: 27px;
}
.text-position1{
  position: absolute;
height: 24px;
left: 32px;
right: 56px;
top: 20px;
}
.text-style{
  font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
letter-spacing: -0.011em;
color: #FFFFFF;
left: 30px;
margin-left: 30px;
top: 13px;
position: relative;
}
.qpatient-text-style {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #FFFFFF;
  left: -5px;
  margin-left: 30px;
  top: 13px;
  position: relative;
}
.selectdrop{
border-radius: 6px;
border: 1px solid #747576;
color: #000;
font-family: "inter";
font-weight: 500;
font-size: 14px;
}
.statuscount{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
.dashboardsearch{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 6px 12px;
gap: 8px;
width: 400px;
height: 36px;
background: #FFFFFF;
border: 1px solid #efe6e6;
border-radius: 4px;
flex: none;
order: 0;
flex-grow: 0;
}
.onhover:hover{
  background-color:#fff ;
}
.btn-group{
  background-color:  #d9dcde;
}
.cal-btn{
  width: 68px;
height: 33px;
font-size: 14px;
font-weight: 600;
background: #FFFFFF;
border: 1px solid #D5D5D5;
border-bottom: 1px solid #D5D5D5;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
border-radius: 3px;
}
.button_outer {background: #fff;border-width: 1px; text-align: center; height: 44px; width: 300px; display: inline-block; transition: .2s; position: relative; overflow: hidden;}
.btn_upload {padding: 12px 10px 12px;; color: #5b66b3; text-align: center; position: relative; display: inline-block; overflow: hidden; z-index: 3; white-space: nowrap;}
.btn_upload input {position: absolute; width: 100%; left: 0; top: 0; width: 100%; height: 105%; cursor: pointer; opacity: 0;}
.stickyheader{
  transition: top 0.5s ease-in-out;
  position: sticky;
  top: 0;
}
.hideMenu{
  width:"80px";
 overflow-y: hidden;
 overflow-x: hidden;
}
.log-btn {
  display: inline-block;
  width: 20rem;
  height: 40px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 10px solid #03045e;
  position: relative;
  overflow: hidden;
  font-size: inherit;
  text-align: center;
  transition: all 0.5s ease-in;
  z-index: 1;
  background: #000000 !important;
}
 .nav-button{
  background-color: #000000;
  border-radius: 5px;
 }
 .nav-button2{
  background-color: #000000;
  border-radius: 5px;
  width: 50px;
 }
.mainpanelbg{
background:#F7F7F7;
overflow-y: auto !important;
}
.card-db{
  height: 147px;
  width: 140.29px;
  background: #ffffff;
  border-radius: 5.78px;
  border:0.72px solid #E7E7E7;
  box-shadow: 0px 1.45px 1.45px 0px #0000001A;

}

.card_text{
  font-size:
  14.46px ;
  font-weight:400 ;
  color:#767676 ;
  Line-height:
17.63px;
}
.card_count{
  font-size:
  26.03px ;
  font-weight:600 ;
  color:#000000 ;
  Line-height:
31.73px;
}
.gMzYpg {
background: #f7f7f7!important;
  min-height: 52px;
}
.custom-table-border {
  border-top: 1px solid #A4A4A4;  
  border-left: 1px solid #A4A4A4; 
  border-right: 1px solid #A4A4A4;  
}
.iUkeKl {
  background-color: none !important
}

.iKKuto{
  background: #ffffff !important;
  border-radius: 0px 0px 5px !important;
  border-top: 1px solid rgba(135, 134, 134, 0.12) !important
}
.kkyWxj {

  border-radius: 5px 5px 0px 0px !important;

}
.btn-black {
  background-color: black !important;
  border-color: black !important;
  color: white !important;
}
.banner-section1 {
  display: flex;
  height: 100vh;
}
.text-section {
  width: 80%;
  background-color: #f7f7f7; 
  display: flex;

}
.black-section {
  width: 20%; 
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.split-image {
  position: absolute;
  left: -65%; 
  width: 120%;
  height: 89%; 
  margin-top: 5rem;
}

.calendar_default_corner .calendar_default_corner_inner + div {
  display: none;
}

.month_default_loading + div {
  display: none;
}

@keyframes rotate-once {
  0% {
    transform: rotate(0deg);
    
  }
  100% {
    transform: rotate(180deg);
  }
}
.rotate-icon {
  display: inline-block;
  cursor: pointer;
}
.rotate-icon:hover {
  animation: rotate-once 0.6s ease-in-out forwards;
}
.buttonStyle {
  background: #000;
  color: white;
  transition:  0.3s, color 0.3s;
}
/* .buttonStyle:hover {
  background: white;
  color: black;
} */
.buttonStyleCancle {
  background: white;
  color: black;
  transition:  0.3s, color 0.3s;
    padding: 10px;
}
/* .buttonStyleCancle:hover {
  background: #000;
  color: white;
} */

.tab-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between tabs */
}

.tab {
  width: calc(25% - 10px); /* Four tabs per row with gap */
  height: 37px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  text-align: center;
  line-height: 37px; /* Centering the text vertically */
  cursor: pointer;
}

.tab-selected {
  width: calc(25% - 10px); /* Four tabs per row with gap */
  height: 37px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  line-height: 37px; /* Centering the text vertically */
  cursor: pointer;
}

.img_size {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

.book_app{
font-weight: 600;
font-size: 32px;
color: #000000;
margin-top: 10px;
}
.dropoption{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #AAAAAA !important;
}

.form-control2{
  /* border: 1px solid #747576; */
  border-radius: 5px !important;
  color: #fff;
  font-family: "inter";
  /* font-size: 1rem; */
  font-size: 14px;
  font-weight: 500;
  padding: .25rem .75rem;
}

.price {
  margin-right: 0;
  text-align: right;
  width: 100px;
}

.border-card{
background: none;
border: 1px solid #000;
}

.fieldRange input{
  border: none;
}

.cpt textarea{
  padding-bottom: 1.625rem;
}

.selectdrop .css-1n7v3ny-option {
  background-color: transparent !important; /* Ensure no row highlighting */
}

.accordion-button:not(.collapsed) {

  background-color: #e8e8ea !important;

}
div:has(li.xyz) {display: none; }


