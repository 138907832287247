/* Scss variable  Created By Bikash on 21-Jan-2019 */
/* GRADIENT LISTS
============================================= */

@font-face {
  font-family: 'Font-Light';
  src: url('../fonts/DIN2014-Regular.eot');
  src: local('../fonts/DIN 2014 Regular'), local('DIN2014-Regular'),
      url('../fonts/DIN2014-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN2014-Regular.woff') format('woff'),
      url('../fonts/DIN2014-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Font-bold';
  src: url('../fonts/DIN2014-ExtraBold.eot');
  src: local('../fonts/DIN 2014 ExtraBold'), local('DIN2014-ExtraBold'),
      url('../fonts/DIN2014-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/DIN2014-ExtraBold.woff') format('woff'),
      url('../fonts/DIN2014-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
* {
  padding: 0;
  margin: 0;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

body {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  color: #232424;
  font-family: Font-Light;
  background: #fff;
}
a {
  transition: all 0.2s ease-in-out;
  color: #28876E;
  text-decoration: none;
}
a:hover, a:focus {
  color: #ff9800;
  text-decoration: none;
}
.login-container{
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../css/img/privademics12.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  
}
.login-bg{
  /* background: linear-gradient(-90.86deg,#5E81F4 .01%,  #edeef0  120.52%); */
  background: linear-gradient(-120.86deg,#F5F7F6 .01%,  #5CA0F2  99.52%);
   /*  */
}
.page{
  opacity: 0.5;
}
.login-left{
  display: none;
  flex: 0 0 40%;
  position: relative;
  justify-content: left;

}
.login-left img{
 width: 100%;
 height: 100%;
 object-fit: contain;
 object-position: top;
}
.login-left h2{
  font-family: 'Barlow', sans-serif;
    color: #fff;
    position: absolute;
    bottom: 1rem;
    font-size: 5rem;
    padding: 4rem;
}


.login-right{
  height: 100vh;
  overflow: hidden;
  padding: 1.5rem;
  /* position: relative;
  display: flex; */
  align-items: center;
  flex-flow: column;
  width: 100%;
}
.login-right .logo{
  margin-bottom: 2rem;
  text-align: center;
}
.login-right .logo img{
  height: 55px;
}

.login {
  display: flex;
  flex-flow: column;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  width: 265px;
  margin: 0 auto;
}

p {
  margin-bottom: 1rem;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #515151;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #515151;
}

::placeholder {
  color: #515151;
}


.login__header {
  text-align: center;
  margin-bottom: 4rem;
}
.login__header img {
  height: 40px;
  margin-bottom: 0;
}

.login__content h1 {
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #000;
  font-family: font-bold;
}
.login .log-link {
  text-align: center;
  padding: 0.4rem 1.5rem;
  transition: all ease-in-out 0.5s;
  color: #ffffff;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  font-size: 1rem;
  text-decoration: none;
  box-shadow: 0 4px 8px #dedede;
 font-weight: 400;
}
.login .log-link:hover {
  color: #000;
  background: #fff;
}
.login .mt-1 {
  margin-top: 0.5rem;
}
.login .mb-0 {
  margin-bottom: 0 !important;
}
.login .mb-1 {
  margin-bottom: 0.5rem;
}
.login .mb-2 {
  margin-bottom: 1rem;
}
.login .mb-3 {
  margin-bottom: 1.5rem;
}
.login .mb-4 {
  margin-bottom: 2rem;
}
.login .mb-5 {
  margin-bottom: 3rem;
}
.login .text-right {
  text-align: right;
}
.login .text-center {
  text-align: center;
}
.login .form-group {
  position: relative;
}
.login .passwordshowbtn {
  position: absolute;
  right: 16px;
  top: 20px;
}
.login .passwordshowbtn img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  height: 17px;
}
.login .passwordshowbtn:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.login .form-control {
  display: block;
  width: 100%;
  height: calc(2.6em + .75rem + 5px);
  font-size: 0.9rem;
  line-height: 1.2;
  color: #000;
  font-family: 'Font-Light';
}
.login .form-control:hover, .login .form-control:focus {
  outline: none;
}

.control-group {
  position: relative;
  margin-bottom: 1rem;
  background: #fff;
}
.control-group label {
  position: absolute;
  left: 15px;
  top: 17px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 2;
  color: #767578;
}
.control-group label.input-group-label {
  left: 45px;
}
.control-group .form-group {
  margin-bottom: 0rem;
}
.control-group.focused label {
  top: .3rem;
  font-size: 0.8rem;
}
.control-group.focused label.input-group-label {
  left: 0;
}

.control-group .form-control {
  border: 0;
  font-weight: 600;
  position: relative;
  box-shadow: 0 1px 6px #00000029 inset;
  border-radius: 4px;
  overflow: hidden;
  padding: 0.6rem 1rem .1rem;
  background: #fff;
}


.control-group .input-group-text {
  border: 0;
  background: transparent;
  border-bottom: 1px solid #ced4da;
  box-shadow: none;
}
@media screen and (min-width:380px) {
  .login {
    width: 300px;
  }
}


@media screen and (min-width: 768px) {
  body {
    height: 100vh;
    /* overflow: hidden; */
    background: #fff;
   
  }
}

@media screen and (min-width: 992px) {
  .login-left {
    display: flex;
    flex: 0 0 40%;
    position: relative;
    height: 100vh;
    max-width: 40%;
    overflow: hidden;
}
.login-left h2{
  font-family: 'Barlow', sans-serif;
    color: #fff;
    position: absolute;
    bottom: 1rem;
    font-size: 3rem;
    padding: 2rem;
}
.login-right .logo {
 
  position: absolute;
  right: 1.5rem;
}
.login {
 
  height: 100vh;
}
}
@media screen and (min-width: 1370px) {
  .login-left h2{
      font-size: 4rem;
      padding: 3rem;
  }
  .login__content h1 {
    font-size: 1.4rem;
  }
  .login {
    width: 330px;
}
}
@media screen and (min-width: 1440px) {
  .login-left h2{
      font-size: 5rem;
      padding: 3rem;
  }
}